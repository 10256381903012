import { Loading02Icon } from "hugeicons-react";
import React from "react";

export const PrimaryButton = ({ onClick, buttonText, processingText, isLoading, iconClass }) => {
    return (
        <button onClick={onClick} className="primary-button">
            {
                isLoading
                    ? <>
                        <Loading02Icon className="loading-icon" />
                        {processingText || 'Loading'}
                    </>
                    : <>
                        {iconClass}
                        {buttonText || 'Button text'}
                    </>  // Default button text if none provided by parent component
            }
        </button>
    );
}


export const SecondaryButton = ({ onClick, buttonText, processingText, isLoading, iconClass }) => {
    return (
        <button onClick={onClick} className="secondary-button">
            {
                isLoading
                    ? <>
                        <Loading02Icon className="loading-icon" />
                        {processingText || 'Loading'}
                    </>
                    : <>
                        {iconClass}
                        {buttonText || 'Button text'}
                    </>  // Default button text if none provided by parent component
            }
        </button>
    );
}