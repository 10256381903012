import React, { useState, useEffect, useRef } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/forms/buttons';
import { TextInput, DataListInput, DateInput } from '../../../components/forms/Input';
import api, { API_URL } from '../../../api';
import toast from 'react-hot-toast';
import axios from 'axios';
import Cookies from 'js-cookie';

const EditMeasureForm = ({ measureId, measureData, onClose }) => {

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);
    const [formData, setFormData] = useState({
        name: '',
        category_name: '',
        description: '',
        target_value: '', 
        type: '',
        startDate: '',  
        endDate: ''     
    });
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState(['numerical', 'percentage']);
    useEffect(() => {
        
        if (measureData) {
            setFormData({
                name: measureData.name || '',
                category_name: measureData.category_name || '',
                description: measureData.description || '',
                target_value: measureData.target_value || '',  
                type: measureData.type || '',
                // startDate: measureData.startDate || '',  
                // endDate: measureData.endDate || '' 
            });
        }
    }, [measureData]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/categories/list/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const categoryNames = response.data.map(category => category.name);
                setCategories(categoryNames);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories');
            }
        };

        fetchCategories();
    }, []);

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            category: formData.category_name,
            name: formData.name,
            description: formData.description, 
            target_value: formData.target_value,  
            type: formData.type,  
            // startDate: formData.startDate,
            // endDate: formData.endDate
        };

        try {
            const response = await api.put(`${API_URL}/measures/update-measure/${measureId}/`, payload);
            if (response.status === 200) {
                toast.success('Measure updated successfully');
                onClose();
                window.location.reload();
            }
        } catch (error) {
            toast.error(error.response?.data?.error || 'Error updating measure');
        }
    };

    return (
        <form onSubmit={handleSubmit} ref={popupRef}>
            <div className="form-group">
                <TextInput
                    label="Measure Name"
                    name="name"
                    value={formData.name}
                    setValue={(value) => handleInputChange('name', value)}
                />
                <DataListInput
                    label="Category"
                    name="category_name"
                    value={formData.category_name}
                    setValue={(value) => handleInputChange('category_name', value)}
                    options={categories}
                />
            </div>
            
            <textarea
                className='text-area-measure'
                name='description'
                placeholder='Measure Descriptions'
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
            ></textarea>

            <div className="form-group">
                {/* <TextInput
                    label="Target Value"
                    name="target_value"  
                    value={formData.target_value} 
                    setValue={(value) => handleInputChange('target_value', value)}
                /> */}
               <DataListInput
                    label="Type"
                    name="type"
                    value={formData.type}
                    setValue={(value) => handleInputChange('type', value)}
                    options={types}
                />

            </div>

            {/* <div className='dateInput'>
                <div className='label-dates'>
                    <label>Start Date</label>
                    <DateInput
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={(e) => handleInputChange('startDate', e.target.value)}
                    />
                </div>
                <div className='label-dates'>
                    <label>End Date</label>
                    <DateInput
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={(e) => handleInputChange('endDate', e.target.value)}
                    />
                </div>
            </div> */}

            <div className="buttons">
                <SecondaryButton buttonText="Cancel" onClick={onClose} />
                <PrimaryButton type="submit" buttonText="Update Measure" />
            </div>
        </form>
    );
};

export default EditMeasureForm;
