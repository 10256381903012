import React from 'react'
import { tableData } from '../../data/tableData'
import { InformationCircleIcon } from 'hugeicons-react';

function SinglePageTable({measuresTableData}) {



  return (
    <>
    <div className="table">
                
        <table>
            <thead>
                <tr>
                    <th>Measures Name</th>
                    <th className='bm'>Goal</th>
                    <th>01</th>
                    <th>02</th>
                    <th>03</th>
                    <th>04</th>
                    <th>05</th>
                    <th>06</th>
                    <th>07</th>
                    <th>08</th>
                    <th>09</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                </tr>
            </thead>
            
            <tbody>
    {
        measuresTableData && Object.keys(measuresTableData).length > 0 && (
            <>
                {["CENSUS - VOLUME & UTILIZATION", "CLINICAL QUALITY", "HUMAN RESOURCES"].map((category) => {
                    const measuresInCategory = Object.keys(measuresTableData).filter(measure => 
                        measuresTableData[measure].category === category // Assuming your data has a 'category' field
                    );

                    return (
                        <React.Fragment key={category}>
                            <tr>
                                <td colSpan={13} style={{ fontWeight: 'bold' }}>{category}</td>
                            </tr>
                            {measuresInCategory.map((measureData, index) => (
                                <tr key={index}>
                                    

          <td className="hover-container" 
          
          >
          {measureData}
          <div className="hover-info">
          <InformationCircleIcon color="#CCCCCC"/>

          <div className="hover-description">
          {measuresTableData[measureData].measure_description} 
            </div>
          </div>
          </td>
                                    <td className='bm'>{measuresTableData[measureData].BM !== null? measuresTableData[measureData].BM: "-"}
                                    {measuresTableData[measureData].measure_unit === "percentage" && (measuresTableData[measureData].BM || measuresTableData[measureData].BM == 0)? "%": ""}
                                    </td>
                                    {[...Array(12)].map((_, monthIndex) => (
                                        <td key={monthIndex + 1}
                                        className={
                                            (measuresTableData[measureData].BM  === null)? "":
                                            measuresTableData[measureData]["months"][monthIndex + 1] < measuresTableData[measureData].BM 
                                            // && measuresTableData[measureData].value_condition == "Lower is better"
                                              ? "green-bg"
                                              : (measuresTableData[measureData]["months"][monthIndex + 1] > measuresTableData[measureData].BM )
                                                // && measuresTableData[measureData].value_condition == "Lower is better")
                                              ? "red-bg"
                                              : (measuresTableData[measureData].BM === null)? ""
                                              : "yellow-bg"
                                          }
                                        >
                                            {measuresTableData[measureData]["months"][monthIndex + 1]}
                                            {measuresTableData[measureData].measure_unit === "percentage" && (measuresTableData[measureData]["months"][monthIndex + 1] || measuresTableData[measureData]["months"][monthIndex + 1] == 0) ? "%": ""}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </React.Fragment>
                    );
                })}
            </>
        )
    }
</tbody>

        </table>
    </div>
    </>
  )
}

export default SinglePageTable