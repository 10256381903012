import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function SinglePageBarChart({ singleBarChartData, hospitalId }) {

  const [monthData, setMonthData] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [measureBy, setMeasureBy] = useState('ED Mortality Rate');
  

  useEffect(() => {
    // Handle case where singleBarChartData is undefined or null
    if (!singleBarChartData) {
      console.warn('singleBarChartData is undefined or null.');
      setMeasures([]);
      setMonthData([]);
      return; // Exit the useEffect early if data is not available
    }

    // Reset the measures and month data
    setMeasures([]);

    const uniqueMeasures = new Set();

for (let measure in singleBarChartData) {
  uniqueMeasures.add(measure);
}

setMeasures([...uniqueMeasures]);
    
    const measureTransform = measureBy
    setMonthData([])
    for (let month in singleBarChartData[measureTransform]){
  
      setMonthData(prevMonths =>[...prevMonths,{"month":month,"value":singleBarChartData[measureTransform][month]}])    
      
    }
    

  }, [measureBy, singleBarChartData, hospitalId]);

  const handleMeasureBy = (value) => {
  
    setMeasureBy(value)
  
  };

  return (
    <>
      <div className="bar-chart">
        <div className="filter">
          <h2>Measures By</h2>
          <Select
            onChange={(e) => {
              handleMeasureBy(e.target.value);
            }}
            name="measureBy"
            id="measureBy"
            value={measureBy}
          >
            {
              measures.map((measure, index) => (
                <MenuItem key={index} value={measure}>
                  {measure}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <BarChart
          dataset={monthData}
          xAxis={[{ scaleType: 'band', dataKey: 'month', label: "Months" }]}
          series={[{ dataKey: 'value', baseValue: 0 }]}
          height={300}
          colors={['#07aeef']}
          borderRadius={7}
          slotProps={{
            bar: {
              highlightScope: { faded: 'global', highlighted: 'item' },
              highlighted: { fill: '#07AEEF !important' },
              hovered: { fill: '#07AEEF !important' },
              faded: { fill: '#F4FCFF' },
            },
          }}
        />
      </div>
    </>
  );
}

export default SinglePageBarChart;
