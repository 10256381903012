import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../api';
import { PrimaryButton, SecondaryButton } from "../../components/forms/buttons";
import toast from 'react-hot-toast';

const DeleteHospitalPopup = ({ hospital, onClose, onDeleteHospital }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteHospital = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const token = Cookies.get('accessToken');
    
    try {
        const response = await axios.delete(`${API_URL}/hospitals/delete_hospital/${hospital.id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });


        if (response.data.message === 'Hospital deleted successfully') {
            onDeleteHospital(hospital.id);
            toast.success('Hospital deleted successfully!');
            onClose();  
        } else {
           
            console.error('Unexpected response:', response.data);
            toast.error('Failed to delete hospital: ' + response.data.message || 'Unknown error');
        }
    } catch (error) {
        console.error('Error deleting hospital:', error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            toast.error('Error while deleting hospital: ' + (error.response.data.detail || 'Unknown error'));
        } else {
            toast.error('Something went wrong. Please try again');
        }
    } finally {
        setIsSubmitting(false);
    }
};

  return (
    <div className="popup popup-overlay">
      <div className="popup_inner add-target-popup">
        <h3>Are you sure you want to delete this hospital? (With ID {hospital.id})</h3>
        <div className='action-btn'>
          <PrimaryButton 
            onClick={handleDeleteHospital} 
            buttonText="Delete" 
            processingText={'Deleting'} 
            isLoading={isSubmitting} 
          />
          <SecondaryButton onClick={onClose} buttonText="Cancel" />
        </div>
      </div>
    </div>
  );
};

export default DeleteHospitalPopup;
