import React from 'react'
import "../../assets/css/popups/messageDescription.css"

const MessageDescription = ({ target = "target component" }) => {
  return (
    <div className="message-description">
      <p>You're about to update the {target} details</p>
    </div>
  )
}

export default MessageDescription;
