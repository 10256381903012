import React, { useState } from "react";
import { Delete01Icon, PencilEdit02Icon, DashedLine01Icon, Calendar03Icon, ViewIcon } from "hugeicons-react";
import "../../assets/css/position/position.css";
import DeletePositionPopup from "../positionPopup/DeletePositonPopup";

const PositionCard = ({ id, name, description, dateCreated, onEditClick, onDelete, onView }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showViewPopup, setShowViewPopup] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleOptions = (event) => {
        event.stopPropagation(); 
        setShowOptions(!showOptions);
    };

    const handleEditClick = (event) => {
        event.stopPropagation();  
        onEditClick({ id, name, description, dateCreated });
        setShowOptions(false); 
    };

    const handleDeleteClick = (event) => {
        event.stopPropagation();  
        setShowDeletePopup(true);
        setShowOptions(false);
    };
    const handleViewClick = (event) => {
        event.stopPropagation();
        onView({ id, name, description, dateCreated });
        setShowOptions(false);
    };
    
    const handleConfirmDelete = async () => {
        setIsSubmitting(true);
        try {
            await onDelete(id);
            setShowDeletePopup(false);
        } catch (error) {
            console.error("Delete failed", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClosePopup = () => {
        setShowDeletePopup(false);
    };

    return (
<div className="position-card" onClick={() => setShowOptions(false)} >
            <div className="position-contents">
                <div className="id_icon" onClick={toggleOptions}>
                    <span className="position-id">{id}</span>
                    <DashedLine01Icon size={20} color={"#000000"} variant={"stroke"} />
                </div>
                <h3 className="position-name">{name}</h3>
                <p className="position-description">{description}</p>
                <p className="position-date">
                    <Calendar03Icon size={24} color={"#000000"} variant={"stroke"} />
                    {dateCreated}
                </p>
                {showOptions && (
                    <div className="position-data" id="popup-overlay">
                        <div className="action-button">
                            <div className="delete" onClick={handleViewClick}>
                                <p className="edit-text"><ViewIcon /> View details</p>
                            </div>
                            <div className="edit" onClick={handleEditClick}>
                                <p className="edit-text"><PencilEdit02Icon /> Edit</p>
                            </div>
                            <div className="line"></div>
                            <div className="delete" onClick={handleDeleteClick}>
                                <p><Delete01Icon /> Delete</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showDeletePopup && (
                <DeletePositionPopup
                    positionId={id}
                    onClose={handleClosePopup}
                    onDelete={handleConfirmDelete}
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default PositionCard;
