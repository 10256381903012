import React, { useState } from 'react'
import { PrimaryButton } from "../../components/forms/buttons"
import { PlusSignIcon } from 'hugeicons-react'
import DashboardContainer from '../../components/dashboard/DashboardContainer'
import NewReportForm from '../../components/forms/report/NewReportForm'

// Mock report data
const mockReports = [
    { id: 1, name: "Performance Measure January Report", target: "Target 1", date_created: "2024-08-22", reporting_date: "2024-08-24" },
    { id: 2, name: "Performance Measure January Report", target: "Target 2", date_created: "2024-08-20", reporting_date: "2024-08-23" },
    { id: 3, name: "Performance Measure January Report", target: "Target 3", date_created: "2024-08-18", reporting_date: "2024-08-22" },
    { id: 3, name: "Performance Measure January Report", target: "Target 3", date_created: "2024-08-18", reporting_date: "2024-08-22" },
    { id: 3, name: "Performance Measure January Report", target: "Target 3", date_created: "2024-08-18", reporting_date: "2024-08-22" },
];

// ReportsTable component with checkboxes and actions
const ReportsTable = ({ reports }) => {
    const [selectedReports, setSelectedReports] = useState([])

    const handleSelectReport = (reportId) => {
        // Add or remove selected report ID from the array
        const index = selectedReports.indexOf(reportId)
        if (index === -1) {
            setSelectedReports([...selectedReports, reportId])
        } else {
            setSelectedReports(selectedReports.filter((id) => id !== reportId))
        }
    }

    return reports.length < 1 ? 'No reports found' : (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Report Name</th>
                        <th>Date Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((report) => (
                        <tr key={report.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedReports.includes(report.id)}
                                    onChange={() => handleSelectReport(report.id)}
                                />
                            </td>
                            <td>{report.name}</td>
                            <td>{report.date_created}</td>
                            <td>
                                <button onClick={() => alert(`Editing ${report.name}`)}>Edit</button>
                                <button onClick={() => alert(`Deleting ${report.name}`)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="selected-reports">
                {selectedReports.length > 0 && (
                    <div>
                        <h4>Selected Reports:</h4>
                        <ul>
                            {selectedReports.map((reportId) => {
                                const report = reports.find((r) => r.id === reportId)
                                return <li key={reportId}>{report.name}</li>
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}

const ReportPageContent = () => {
    const [reportsData] = useState(mockReports)
    const [showNewReportForm, setShowNewReportForm] = useState(false)

    return (
        <div className='reports-page'>
            {showNewReportForm && (
                <div className="popup new-report-form-popup">
                    <div className="popup-content">
                        <NewReportForm setShowNewReportForm={setShowNewReportForm} />
                    </div>
                </div>
            )}

            <div className="action">
                <PrimaryButton iconClass={<PlusSignIcon />} buttonText={'New report'} onClick={() => setShowNewReportForm(true)} />
            </div>

            <div className="reports">
                <ReportsTable reports={reportsData} />
            </div>
        </div>
    )
}

const ReportPage = () => {
    return (
        <DashboardContainer content={<ReportPageContent />} pageTitle={'Reports'} />
    );
};

export default ReportPage;
