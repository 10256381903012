import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './msalConfig'; 
import { TextInput } from './Input'; 
import { PrimaryButton } from './buttons'; 
import { Link } from 'react-router-dom';
import  { API_URL } from '../../api'
import Documentation from '../../pages/documentation/Documentation';


const fetchUserPermissions = async (accessToken) => {
    try {
        const response = await axios.get(`${API_URL}/user/profile/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        const user = response.data;

        // Store user details in localStorage
        localStorage.setItem('position', user.position);
        localStorage.setItem('hospital', user.hospital);
        localStorage.setItem('hospital_id', user.hospital_id);

        // Redirect user to the home page
        window.location.href = '/';
    } catch (error) {
        console.error('Error fetching user permissions:', error);
        toast.error('An error occurred while fetching user permissions.');
    }
};

const LoginForm = () => {
    const { instance } = useMsal();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Handle normal login
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast.error('Please fill in all fields.');
            return;
        }
        setIsLoading(true);
        try {
            
            const credentials = { email, password,auth_type:"email_password" };
            console.log(credentials);
            
            const response = await axios.post(`${API_URL}/accounts/token/`, credentials);
            const { access_token, refresh_token } = response.data;

            // Store tokens in cookies
            Cookies.set('accessToken', access_token);
            Cookies.set('refreshToken', refresh_token);

            // Fetch user permissions
            await fetchUserPermissions(access_token);

            toast.success('Successfully logged in');
        } catch (error) {
            console.error('Login failed:', error);
            toast.error(error.response.data.error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMicrosoftLogin = async () => {
        try {
            const loginResponse = await instance.loginPopup(loginRequest);
            const microsoftAccessToken = loginResponse.accessToken;
            const email = loginResponse.account.username

            const credentials = { email, access_token:microsoftAccessToken, auth_type:"microsoft" };

            try {
                const response = await axios.post(`${API_URL}/accounts/token/`, credentials);
                const { access_token, refresh_token } = response.data;
            
                if (response.status === 200){
                    Cookies.set('accessToken', access_token);
                    Cookies.set('refreshToken', refresh_token);
                    await fetchUserPermissions(Cookies.get('accessToken'));
                    toast.success('Successfully logged in with Microsoft');
                }
                
            } catch (error) {
                toast.error(error.response.data.error);
            }
            
            
        } catch (error) {
            console.error('Microsoft login failed:', error);
            toast.error(' We are having trouble accessing your Microsoft Account...');
        }
    };
    

    return (
        <div className="form">
            {/* Normal Login Form */}
            <form onSubmit={handleLogin}>
                <TextInput
                    type="email"
                    name="email"
                    value={email}
                    setValue={setEmail}
                    placeholder="Enter your email"
                />
                <TextInput
                    type="password"
                    name="password"
                    value={password}
                    setValue={setPassword}
                    placeholder="Enter your password"
                />
                <PrimaryButton
                    buttonText="Login"
                    processingText="Logging in"
                    onClick={handleLogin}
                    isLoading={isLoading}

                />

                 {/* Microsoft Login Button */}
            {/* <div className="microsoft-login">
                <button className="primary-button" onClick={handleMicrosoftLogin}>
                    Sign in with Microsoft
                </button>
            </div> */}
            </form>

        
            {/* Forgot Password Link */}
            {/* <span>
                Forgot password? <Link to="/forgot-password/" className="form-link">Click here</Link>
            </span> */}
            {/* <div className='documentation'>
            <Link to={"/documentation/"}>Documentation</Link>
            </div> */}
           
        </div>
    );
};

export default LoginForm;
