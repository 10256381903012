import axios from 'axios'
import Cookies from 'js-cookie'
import { IdVerifiedIcon, Loading03Icon, Mail01Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { API_URL } from '../../api'
import { PrimaryButton } from './buttons'
import { TextInput } from './Input'

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!email) {
            toast.error("Email is required")
        }

        const credentials = {
            "email": email
        }
        try {
            setIsLoading(true)
            const response = await axios.post(`${API_URL}/accounts/forgot-password/`, credentials)
            if (response.status === 200) {
                Cookies.set("resetPasswordEmails", email)
                toast.success("Password reset email sent successfully")
                window.location.href = '/reset-password/'
                setEmail("")
                setIsLoading(false)
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Something went wrong. Please try again')
            }
            setIsLoading(false)
        }
    }
    return (
        <div className='form'>
            <form>
                <TextInput iconClass={<Mail01Icon />} value={email} setValue={setEmail} placeholder={'Enter your email'} type={'email'} name={'userEmail'} id={'userEmail'} />
            </form>
            <PrimaryButton iconClass={<IdVerifiedIcon />} onClick={handleSubmit} buttonText={'Reset Password'} processingText={'Verifying'} isLoading={isLoading} />

        </div>
    )
}

export default ForgotPasswordForm
