// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import toast from 'react-hot-toast';
// import { API_URL } from '../../api';
// import { PrimaryButton, SecondaryButton } from '../../components/forms/buttons';
// import { DataListInput, TextInput } from '../../components/forms/Input';

// const AddUserForm = ({ isOpen, onClose, onUserAdded }) => {
//   const [username, setUsername] = useState('');
//   const [email, setEmail] = useState('');
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [permission, setPermission] = useState('');
//   const [permissions, setPermissions] = useState([]);
//   const [position, setPosition] = useState('');
//   const [positions, setPositions] = useState([]);
//   const [hospital, setHospital] = useState('');
//   const [hospitals, setHospitals] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const popupRef = useRef(null);

//   // Fetch hospitals
//   useEffect(() => {
//     const fetchHospitals = async () => {
//       try {
//         const token = Cookies.get('accessToken');
//         const response = await axios.get(`${API_URL}/hospitals/`, {
//           headers: { 'Authorization': `Bearer ${token}` },
//         });
//         const hospitalsData = response.data;
//         const hospitalNames = hospitalsData.map(hospital => hospital.name);
//         setHospitals(hospitalNames);
//       } catch (error) {
//         console.error('Error fetching hospitals:', error);
//         toast.error('Failed to load hospitals data');
//       }
//     };
//     fetchHospitals();
//   }, []);

//   // Fetch permissions
//   useEffect(() => {
//     const fetchPermissions = async () => {
//       try {
//         const token = Cookies.get('accessToken');
//         const response = await axios.get(`${API_URL}/user/list_permisions/`, {
//           headers: { 'Authorization': `Bearer ${token}` },
//         });
//         const permissionsData = response.data.permissions;
//         setPermissions(permissionsData);
//       } catch (error) {
//         console.error('Error fetching permissions:', error);
//         toast.error('Failed to load permissions data');
//       }
//     };
//     fetchPermissions();
//   }, []);

//   // Fetch positions
//   useEffect(() => {
//     const fetchPositions = async () => {
//       try {
//         const token = Cookies.get('accessToken');
//         const response = await axios.get(`${API_URL}/position/`, {
//           headers: { 'Authorization': `Bearer ${token}` },
//         });
        
//         // Log the response to ensure it's what you expect
//         console.log("Positions API Response:", response.data);

//         const positionData = response.data; // Since the response is an array, we can set it directly
//         setPositions(positionData); // Set the entire positions array directly
//       } catch (error) {
//         console.error('Error fetching positions:', error);
//         toast.error('Failed to load positions data');
//       }
//     };
//     fetchPositions();
//   }, []);

//   const handleClickOutside = (event) => {
//     if (popupRef.current && !popupRef.current.contains(event.target)) {
//       onClose();
//     }
//   };

//   useEffect(() => {
//     if (isOpen) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isOpen]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newUser = {
//         users: [
//             {
//                 username,
//                 email,
//                 first_name: firstName,
//                 last_name: lastName,
//                 position,
//                 hospital,
//             }
//         ],
//         permission, // from selected permission
//     };

//     // Validation checks
//     if (!username || !email || !firstName || !lastName || !position || !hospital || !permission) {
//         toast.error('Please fill in all required fields');
//         return;
//     }

//     console.log("Request Payload:", newUser);

//     try {
//       const token = Cookies.get('accessToken');
//         const response = await axios.post(`${API_URL}/user/new_user/`, newUser, {
//             headers: {
//                 'Authorization': `Bearer ${token}`,
//                 'Content-Type': 'application/json',
//             },
//         });
//         // Handle successful response...
//         console.log(response.data);
//     } catch (error) {
//         if (error.response) {
//             console.error('Error Response:', error.response.data);
//             toast.error('Error while adding user: ' + (error.response.data.detail || 'Unknown error'));
//         } else {
//             console.error('Error Message:', error.message);
//             toast.error('No response received from server');
//         }
//     }
// };


//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   setIsSubmitting(true);

//   //   if (!hospital || !permission) {
//   //     toast.error('Please fill in all required fields');
//   //     setIsSubmitting(false);
//   //     return;
//   //   }

//   //   try {
//   //     const newUser = {
//   //       users: [{
//   //         username,
//   //         email,
//   //         first_name: firstName,
//   //         last_name: lastName,
//   //         hospital,
//   //         position,
//   //       }],
//   //       permission,
//   //     };

//   //     const token = Cookies.get('accessToken');
//   //     setIsLoading(true);

//   //     const response = await axios.post(`${API_URL}/user/new_user/`, newUser, {
//   //       headers: {
//   //         'Authorization': `Bearer ${token}`,
//   //         'Content-Type': 'application/json',
//   //       },
//   //     });

//   //     if (response.status >= 200 && response.status < 300) {
//   //       toast.success("User successfully added");
//   //       if (typeof onUserAdded === 'function') {
//   //         onUserAdded(response.data);
//   //       }
//   //       onClose();
//   //     } else {
//   //       toast.error("Unexpected response. User may not have been saved.");
//   //     }
//   //   } catch (error) {
//   //     if (error.response) {
//   //       toast.error('Error while adding user: ' + (error.response.data.detail || 'Unknown error'));
//   //     } else {
//   //       toast.error('No response received from server');
//   //     }
//   //   } finally {
//   //     setIsLoading(false);
//   //     setIsSubmitting(false);
//   //   }
//   // };

//   return (
//     <div className={`popup-user ${isOpen ? 'open' : ''}`}>
//       <form ref={popupRef} onSubmit={handleSubmit}>
//         <h2 className="title">Add New User</h2>
//         <p className="paragraph-green">
//           Fill in the required details to add a new user.
//         </p>
//         <div className='form-step'>
//           <TextInput
//             name="username" id="username" placeholder="Username" required
//             value={username}
//             setValue={setUsername}
//           />
//           <TextInput
//             name="email" id="email" placeholder="Email" required
//             value={email}
//             setValue={setEmail}
//           />
//           <TextInput
//             name="first_name" id="first_name" placeholder="First Name" required
//             value={firstName}
//             setValue={setFirstName}
//           />
//           <TextInput
//             name="last_name" id="last_name" placeholder="Last Name" required
//             value={lastName}
//             setValue={setLastName}
//           />

//           <DataListInput
//             id="permission" name="permission" placeholder="Select Permission" required
//             options={permissions}
//             value={permission}
//             setValue={setPermission}
//           />
//           <DataListInput
//             id="position" name="position" placeholder="Select Position" required
//             options={positions.map(pos => pos.name)} // Display the position names
//             value={position}
//             setValue={setPosition}
//           />
//           <DataListInput
//             id="hospital" name="hospital" placeholder="Select Hospital" required
//             options={hospitals}
//             value={hospital}
//             setValue={setHospital}
//           />

//           <div className="popup-buttons">
//             <SecondaryButton onClick={onClose} isLoading={false} buttonText="Cancel">Cancel</SecondaryButton>
//             <PrimaryButton type="submit" isLoading={isSubmitting} buttonText="Save" processingText={'Saving New User'}>Submit</PrimaryButton>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AddUserForm;


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { API_URL } from '../../api';
import { PrimaryButton, SecondaryButton } from '../../components/forms/buttons';
import { DataListInput, TextInput } from '../../components/forms/Input';

const AddUserForm = ({ isOpen, onClose, onUserAdded }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [permission, setPermission] = useState(''); 
  // const [permissions, setPermissions] = useState([]); 
const [position, setPosition] = useState(''); 
const [positions, setPositions] = useState([]); 
const [hospital, setHospital] = useState([]);
const [hospitals, setHospitals] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const popupRef = useRef(null);

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const token = Cookies.get('accessToken');
const response = await axios.get(`${API_URL}/hospitals/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        const hospitalsData = response.data; 
        const hospitalNames = hospitalsData.map(hospital => hospital.name);
        setHospitals(hospitalNames);
  
      } catch (error) {
        console.error('Error fetching hospitals:', error);
        toast.error('Failed to load hospitals data');
      }
    };
  
    fetchHospitals();
  }, []);
  

//   useEffect(() => {
//     const fetchUsersPermissions = async () => {
//       try {
//         const token = Cookies.get('accessToken');
//         const response = await axios.get(`${API_URL}/user/list_users/`, {
//           headers: {
//             'Authorization': `Bearer ${token}`
//           }
//         });
  
//         const users = response.data;
// console.log('users with permission', users);
//         const permissionNames = users.map(user => user.permission); 
// console.log('permisions',permissionNames )
//         const uniquePermissionNames = [...new Set(permissionNames)].filter(Boolean); // Remove undefined values
// console.log('unipermisions',uniquePermissionNames )
//         setPermissions(uniquePermissionNames);
  
//       } catch (error) {
//         console.error('Error fetching users permissions:', error);
//         toast.error('Failed to load users permissions data');
//       }
//     };
  
//     fetchUsersPermissions();
//   }, []);
  
  
  

  useEffect(() => {
const fetchUsersPositions = async () => {
      try {
        const token = Cookies.get('accessToken');
        const response = await axios.get(`${API_URL}/user/list_users/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const users = response.data;
const positionNames = users.map(user => user.position);
const uniquePositionNames = [...new Set(positionNames)];
setPositions(uniquePositionNames);

      } catch (error) {
console.error('Error fetching users position:', error);
    toast.error('Failed to load users positions data');
      }
    };

fetchUsersPositions();
  }, []);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose(); 
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    
    if (!hospital || !position || !username || !email || !firstName || !lastName) {
      toast.error('Please fill in all required fields');
      setIsSubmitting(false);
      return;
    }

    try {
      const newUser = {
        users: [{
          username: username,
          email: email,
          first_name: firstName,
          last_name: lastName,
          hospital: hospital,
          position: position,  
        }],
        // permission: permission,  
      };

      const token = Cookies.get('accessToken');
      setIsLoading(true);

      const response = await axios.post(`${API_URL}/user/new_user/`, newUser, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status >= 200 && response.status < 300) {
        toast.success("User successfully added");
        if (typeof onUserAdded === 'function') {
          onUserAdded(response.data); 
        }
        onClose();
      } else {
        toast.error("Unexpected response. User may not have been saved.");
      }
    } catch (error) {
      if (error.response) {
        toast.error('Error while adding user: ' + (error.response.data.detail || 'Unknown error'));
      } else {
        toast.error('No response received from server');
      }
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
};



  return (
    <div className={`popup-user ${isOpen ? 'open' : ''}`}>
      <form ref={popupRef} onSubmit={handleSubmit}>
        <h2 className="title">Add New User</h2>
        <p className="paragraph-green">
          Fill in the required details to add a new user.
        </p>
        <div className='form-step'>
          <TextInput
            name="username" id="username" placeholder="Username" required
            value={username}
            setValue={setUsername}
          />
          <TextInput
            name="email" id="email" placeholder="Email" required
            value={email}
            setValue={setEmail}
          />
          <TextInput
            name="first_name" id="first_name" placeholder="First Name" required
            value={firstName}
            setValue={setFirstName}
          />
          <TextInput
            name="last_name" id="last_name" placeholder="Last Name" required
            value={lastName}
            setValue={setLastName}
          />
         {/* <DataListInput
  id="permission" name="permission" placeholder="Select a permission" required
  options={permissions}
  value={permission}
  setValue={setPermission}
/> */}

            <DataListInput
id="position" name="position" placeholder="Select a position" required
options={positions}
value={position}
setValue={setPosition}
          />
           <DataListInput
id="hospital" name="hospital" placeholder="Select Hospital" required
options={hospitals}
value={hospital}
setValue={setHospital}
          />

          <div className="popup-buttons">
            <SecondaryButton onClick={onClose} isLoading={false} buttonText="Cancel">Cancel</SecondaryButton>
            <PrimaryButton type="submit" isLoading={isSubmitting} buttonText="Save" processingText={'Saving New User'}>Submit</PrimaryButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;
