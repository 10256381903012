import React from "react";
// import HospitalCard from "../../components/hospitals/HospitalCard";
import "../../assets/css/dashboard/dashboard.css";
import { FileExportIcon, InformationCircleIcon } from "hugeicons-react";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as XLSX from 'xlsx';
import { LineChart } from "@mui/x-charts/LineChart";

import {
  Timer02Icon,
  BedIcon,
  SearchingIcon,
  WorkoutRunIcon,
} from "hugeicons-react";
import DashboardTotalCard from "../../components/dashboard/DashboardTotalCard";
import DashboardContainer from "../../components/dashboard/DashboardContainer";
import api, { API_URL } from "../../api";



const monthToNumber = {
  January: 1, February: 2, March: 3, April: 4, May: 5, June: 6,
  July: 7, August: 8, September: 9, October: 10, November: 11, December: 12
};

const DashboardContent = () => {
  const [currentDateYear, setCurrentDateYear] = useState(
    new Date().getFullYear()
  );
  const [filterDurationYear, setFilterDurationYear] = useState(
    new Date().getFullYear()
  );

  const [prevYear, setPrevYear] = useState(new Date().getFullYear() - 1);
  const [prevYearMinusTwo, setPrevYearMinusTwo] = useState(
    new Date().getFullYear() - 2
  );
  const [prevYearMinusThree, setPrevYearMinusThree] = useState(
    new Date().getFullYear() - 3
  );
  const [filterDurationMonth, setFilterDurationMonth] = useState(
    new Date().getMonth()
  );

  const [measuresDataYear, setMeasuresDataYear] = useState("this_year")

  const [measureBy, setMeasureBy] = useState("ED Mortality Rate");
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tableData, setTableData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [fetchingPerformanceMeasures, setFetchingPerformanceMeasures] = useState(true);

  const [totalData, setTotalData] = useState({});
  const [emmergencyData, setEmmergencyData] = useState({});
  const [maxEmmergencyData, setMaxEmmergencyData] = useState(0);
  const [measuresList, setMeasuresList] = useState('')


 
  const categories = [
    "CENSUS - VOLUME & UTILIZATION",
    "CLINICAL QUALITY",
    "HUMAN RESOURCES"
  ];

  // // fetch data
  const fetchPerformanceMeasures = async (value) => {
    setMeasuresList([])
    try {
      setFetchingPerformanceMeasures(true);
      const response = await api.get(
        `${API_URL}/dashboard/measures_table_data/${value || selectedYear}/${monthToNumber[selectedMonth]}/`
      );
      if (response.status === 200) {
        const data = response.data

        const uniqueMeasures = new Set();
        for (let measure in data) {
          data[measure].forEach(item => {
            uniqueMeasures.add(item["name"]);
          });
        }
        setMeasuresList([...uniqueMeasures]);

        const groupedData = categories.reduce((acc, category) => {
          acc[category] = response.data.measures.filter(el => el.category === category);
          return acc;
        }, {});
        setTableData(groupedData)
        setFetchingPerformanceMeasures(false);
      }
    } catch (error) {
      console.log(error);
      setFetchingPerformanceMeasures(false);
    }
  };

  const fetchTotalsData = async (value) => {
    try {
      const response = await api.get(`${API_URL}/dashboard/dashboard_totals/${value}/${monthToNumber[selectedMonth] || 1}/`);
      if (response.status === 200) {


        setTotalData(response.data[Number(value)]);


      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMeasuresChartData = async (selected_year, measure_by) => {

    try {
      const response = await api.get(
        `${API_URL}/dashboard/measures_chart_data/${selected_year}/${measure_by || "ED Mortality Rate"}/`
      );

      if (response.status === 200) {


        setChatData(
          response.data
            .map((data) =>
              data.measures
                .map((measure) =>
                  measure.hospitals.map((hospital) => ({
                    label: hospital.name,
                    data: hospital.months.map(
                      (monthData) => Object.values(monthData)[0]
                    ),
                  }))
                )
                .flat()
            )
            .flat()
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmergencyData = async (year, month) => {
    try {

      const response = await api.get(
        `${API_URL}/dashboard/emergency_rooms_data/${selectedYear}/${[monthToNumber[selectedMonth]] || 1}/`
      );
      if (response.status === 200) {


        setEmmergencyData(response.data[year][monthToNumber[selectedMonth]]);
        const values = [
          response.data[year][monthToNumber[selectedMonth]]['Carnegie'],
          response.data[year][monthToNumber[selectedMonth]]['Pawhuska'],
          response.data[year][monthToNumber[selectedMonth]]['Seiling'],
          response.data[year][monthToNumber[selectedMonth]]['Prague'],
          response.data[year][monthToNumber[selectedMonth]]['Mangum'],
        ];
        const total = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setMaxEmmergencyData(
          total
        );


      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchMeasuresList()
    fetchTotalsData(selectedYear);
    fetchEmergencyData(selectedYear, selectedMonth);
    fetchPerformanceMeasures();
    fetchMeasuresChartData(selectedYear, measureBy);
  }, [selectedYear, selectedMonth]);

  const handleDurationYearChange = (value) => {
    handleFilter(value, filterDurationMonth, measureBy);
    setFilterDurationYear(value);
    setSelectedYear(value)
  };

  const handleMeasureBy = (value) => {

    setMeasureBy(value);


    handleFilter(filterDurationYear, filterDurationMonth, value);
  };

  const handleSelectedMonth = (value) => {
    setSelectedMonth(value);
  };

  const handleSelectedYear = (value) => {

    if (value === "this_year") {
      const date = new Date().getFullYear()
      setSelectedYear(date);
      setMeasuresDataYear("this_year")

    } else {
      const date = new Date().getFullYear() - 1


      setSelectedYear(date);
      setMeasuresDataYear("last_year")
    }

  };

  const xLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleFilter = (filterDurationYear, filterDurationMonth, measure) => {

    fetchTotalsData(filterDurationYear);
    console.log("filters: ", filterDurationYear, filterDurationMonth, measure);
    fetchPerformanceMeasures(filterDurationYear);
    fetchMeasuresChartData(filterDurationYear, measure);
    fetchEmergencyData(filterDurationYear);
  };

  const handleExport = () => {
    // Check if tableData is available and an object with categories
    if (!tableData || typeof tableData !== 'object' || Array.isArray(tableData)) {
      console.error("No valid data available for export");
      return;
    }

    // Flatten the tableData object into an array for export
    const exportData = Object.keys(tableData).flatMap(category => {
      return tableData[category].map(el => {
        const row = {
          Measures: el.name,
          Goal: el.measure_unit === "percentage" && el.BM !== undefined ? `${el.BM}%` : el.BM,
        };

        // Extract hospital values
        el.hospitals.forEach(hospital => {
          row[hospital.name] = hospital.value !== undefined && el.measure_unit === "percentage" ? `${hospital.value}%` : hospital.value !== undefined ? hospital.value : '-';
        });

        return row;
      });
    });

    // Log the data for debugging
    console.log("Exporting data:", exportData);

    // Create a worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Performance Measures");

    // Export the file
    XLSX.writeFile(wb, "performance_measures.xlsx");
  };
  const getColor = (index) => {
    const colors = [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#F0A500",
    ];
    return colors[index % colors.length];
  };



  return (
    <div className="dashboard-container">
      <div className="top-actions">
        <div className="export-icon export-bttn" onClick={handleExport}>
          <FileExportIcon size={24} />
          <p className="">Export Table</p>
        </div>

        <Select className="filter-year-select"
          onChange={(e) => {
            handleDurationYearChange(e.target.value);
          }}
          name="duration"
          id="duration"
          value={selectedYear}
        >
          <MenuItem value={currentDateYear}>{currentDateYear}</MenuItem>
          <MenuItem value={prevYear}>{prevYear}</MenuItem>
          {/* <MenuItem value={prevYearMinusTwo}>{prevYearMinusTwo}</MenuItem> */}
          {/* <MenuItem value={prevYearMinusThree}>{prevYearMinusThree}</MenuItem> */}
        </Select>
        <Select
          onChange={(e) => {
            handleSelectedMonth(e.target.value);
          }}
          name="selectedMonth"
          id="selectedMonth"
          value={selectedMonth}
        >
          <MenuItem value="January">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </div>
      {fetchingPerformanceMeasures ? (
        <>..Loading</>
      ) : (
        <>
          <div className="total-hospital-cards">
            <DashboardTotalCard
              className="total-card"
              icon={
                <Timer02Icon size={24} color={"#07AEEF"} variant={"stroke"} />
              }
              value={
                totalData ? (
                  <h2>{totalData.total_acute}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Acute"}
            />
            <DashboardTotalCard
              className="total-card"
              icon={<BedIcon size={24} color={"#FFB60A"} variant={"stroke"} />}
              value={
                totalData ? (
                  <h2>{totalData.total_swing_bed}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Swingbed"}
            />
            <DashboardTotalCard
              className="total-card"
              icon={
                <SearchingIcon size={24} color={"#FC7D75"} variant={"stroke"} />
              }
              value={
                totalData ? (
                  <h2>{totalData.total_observation}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Observation"}
            />
            <DashboardTotalCard
              className="total-card"
              icon={
                <WorkoutRunIcon
                  size={24}
                  color={"#0E76BC"}
                  variant={"stroke"}
                />
              }
              value={
                totalData ? (
                  <h2>{totalData.total_emergency_room}</h2>
                ) : (
                  <span>No Available Data</span>
                )
              }
              name={"Total Emergency Room"}
            />
          </div>
          <div className="chart-row">
            <div className="chart-container">
              <div className="row">
                <h4>Measure By: </h4>

                <Select
                  onChange={(e) => {
                    handleMeasureBy(e.target.value);
                  }}
                  name="measureBy"
                  id="measureBy"
                  value={measureBy}
                >
                  {
                    measuresList.map((measure, index) => (

                      <MenuItem key={index} value={measure} >{measure}
                      </MenuItem>

                    ))
                  }

                </Select>
              </div>

              <LineChart
                height={300}
                className="chart"
                series={chatData.map((data, index) => ({
                  data: data.data,
                  label: data.label,
                  color: getColor(index),
                }))}
                xAxis={[{ scaleType: "point", data: xLabels }]}
              />


            </div>
            <div className="progress-container">
              <div className="row">
                <h4>Emergency Room Utilization</h4>
                <p className="description">
                  Percentage of total ER visits across all hospitals. Each percentage represents the proportion of ER visits for each hospital, with the sum totaling 100%.
                </p>
              </div>
              <div className="hospital-progress">
                <div className="row">
                  <span className="name">Carnegie</span>
                  <progress
                    value={
                      emmergencyData.Carnegie
                        ? ((emmergencyData.Carnegie / maxEmmergencyData) * 100).toFixed(2)
                        : 0
                    }
                    max={100}

                  />
                  <span>
                    {emmergencyData.Carnegie
                      ? ((emmergencyData.Carnegie / maxEmmergencyData) * 100).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
                <div className="row">
                  <span className="name">Mangum</span>
                  <progress
                    value={
                      emmergencyData.Mangum
                        ? ((emmergencyData.Mangum / maxEmmergencyData) * 100).toFixed(2)
                        : 0
                    }
                    max={100}
                  />
                  <span>
                    {emmergencyData.Mangum
                      ? ((emmergencyData.Mangum / maxEmmergencyData) * 100).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
                <div className="row">
                  <span className="name">Pawhuska</span>
                  <progress
                    value={
                      emmergencyData.Pawhuska
                        ? ((emmergencyData.Pawhuska / maxEmmergencyData) * 100).toFixed(2)
                        : 0
                    }
                    max={100}
                  />
                  <span>
                    {emmergencyData.Pawhuska
                      ? ((emmergencyData.Pawhuska / maxEmmergencyData) * 100).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
                <div className="row">
                  <span className="name">Prague</span>
                  <progress
                    value={
                      emmergencyData.Prague
                        ? ((emmergencyData.Prague / maxEmmergencyData) * 100).toFixed(2)
                        : 0
                    }
                    max={100}
                  />
                  <span>
                    {emmergencyData.Prague
                      ? ((emmergencyData.Prague / maxEmmergencyData) * 100).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
                <div className="row">
                  <span className="name">Seiling</span>
                  <progress
                    value={
                      emmergencyData.Seiling
                        ? ((emmergencyData.Seiling / maxEmmergencyData) * 100).toFixed(2)
                        : 0
                    }
                    max={100}
                  />
                  <span>
                    {emmergencyData.Seiling
                      ? ((emmergencyData.Seiling / maxEmmergencyData) * 100).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <div className="row">
              <h3>Performance Measures</h3>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Measures</th>
                  <th className="bm">Goal</th>
                  <th>Carnegie</th>
                  <th>Mangum</th>
                  <th>Pawhuska</th>
                  <th>Prague</th>
                  <th>Seiling</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <React.Fragment key={category}>
                    <tr>
                      <td colSpan={5} style={{ fontWeight: 'bold' }}>{category}</td>
                    </tr>
                    {tableData[category].map((el, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="hover-container">
                          {el.name}
                          <div className="hover-info">
                            <InformationCircleIcon color="#CCCCCC" />

                            <div className="hover-description">
                              {el.measure_description}
                            </div>
                          </div>




                        </td>

                        <td className="bm">{el.BM !== null ? el.BM : "-"} {el.measure_unit === "percentage" && (el.BM || el.BM == 0) ? "%" : ""}</td>
                        {el.hospitals && el.hospitals.length > 0 ? (
                          el.hospitals.map((hospital, colIndex) => (
                            <td
                              key={hospital.name}
                              className={
                                (el.BM === null) ? "" :
                                  hospital.value < el.BM && el.value_condition == "Lower is better"
                                    ? "green-bg"
                                    : (hospital.value > el.BM && el.value_condition == "Lower is better")
                                      ? "red-bg"
                                      : (el.BM === null) ? ""
                                        : "yellow-bg"
                              }
                            >
                              {hospital.value !== undefined ? hospital.value : '-'}{el.measure_unit === "percentage" ? "%" : ""}
                            </td>
                          ))
                        ) : (
                          <td colSpan={5}>No hospital data available</td>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

        </>
      )}
    </div>
  );
};

const Dashboard = () => {
  return (
    <DashboardContainer content={<DashboardContent />} pageTitle={"Overview"} />
  );
};
export default Dashboard;