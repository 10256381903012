import { Flag01Icon } from 'hugeicons-react';
import React, { useState, useEffect } from 'react';
import { DataListInput, DateInput, TextInput } from '../forms/Input';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { API_URL } from '../../api';

const AddHospitalPopup = ({ onClose, onSubmit, sectionExpired }) => {
    const [country, setCountry] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [hospitalName, setHospitalName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [measures, setMeasures] = useState([]);

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        // return new Intl.DateTimeFormat('en-US', options).format(date);
        return date.toISOString().split('T')[0];
        
    };

    useEffect(() => {
        const fetchMeasures = async () => {
            const token = Cookies.get('accessToken');
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/measures/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = response.data;
                const measuresArray = []
                data.forEach( item =>{
                
                    measuresArray.push(item['name'])
                })
                
                setMeasures(measuresArray)
                console.log(response.data)
                
            } catch (error) {
                console.error('Error fetching measures:', error);
                toast.error('Failed to load measures');
            }
        };

        fetchMeasures();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const newHospital = {
                name: hospitalName
            };
            console.log(newHospital)
            const token = Cookies.get('accessToken');
            setIsLoading(true);
            const response = await axios.post(`${API_URL}/hospitals/new_hospital/`, newHospital, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200 || response.status === 201) {
                setIsLoading(false);
                toast.success("Hospital successfully added");
                if (sectionExpired) {
                    window.location.reload();
                } else {
                    window.location.href = '/hospitals/';
                }
                onClose();
            } else {
                toast.error("Failed to add Hospital");
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                toast.error('Error while adding target: ' + (error.response.data.detail || 'Unknown error'));
            } else {
                toast.error('Something went wrong. Please try again');
            }
            setIsLoading(false);
        }
        setIsSubmitting(false);
    };

    return (
        <div className='popup-overlay'>
            <form className='add-target-popup' onSubmit={handleSubmit}>
                <h1 className='title'>Add Hospital</h1>
             
                <TextInput
                    type='text'
                    id='targetValue'
                    name='targetValue'
                    placeholder="Hospital Name"
                    value={hospitalName}
                    setValue={setHospitalName}
                />
                {/* <div className='dateInput'>
                    <DateInput
                        date={startDate}
                        setDate={setStartDate}
                        placeholder="Start date"
                        label="Start date"
                        choices={['day', 'month', 'year']}
                    />
                    <DateInput
                        date={endDate}
                        setDate={setEndDate}
                        placeholder="End date"
                        label="End date"
                        choices={['day', 'month', 'year']}
                    />
                </div> */}
                <div className='buttons'>
                    <SecondaryButton
                        onClick={onClose}
                        buttonText='Cancel'
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                        type='submit'
                        processingText='Submitting'
                        buttonText='Save'
                    />
                </div>
            </form>
        </div>
    );
};

export default AddHospitalPopup;
