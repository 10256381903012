import React, { useEffect, useState, useRef } from 'react';
import { PlusSignIcon, Search01Icon, CleanIcon,Calendar03Icon } from 'hugeicons-react';
import { PrimaryButton,SecondaryButton } from '../../components/forms/buttons';
import { Delete01Icon, PencilEdit02Icon, ArrowDown01Icon, ArrowUp01Icon } from 'hugeicons-react';
import AddTargetPopup from '../../components/targets/AddTargetPopup';
import DeleteTargetPopup from '../../components/targets/DeleteTargetPopup';
import EditTarget from '../../components/targets/EditTarget';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import '../../assets/css/targets/targets.css';
import api, { API_URL } from '../../api';
import Cookies from 'js-cookie';
import { TextInput } from '../../components/forms/Input';
import FormattedDate from '../../services/formatDate';

// Search Component
const SearchComponent = ({ searchQuery, handleSearch }) => (
  <div className="search-input">
    <TextInput 
      iconClass={<Search01Icon />} 
      type={'search'} 
      placeholder={'Search by measure name'} 
      value={searchQuery} 
      setValue={handleSearch} 
    /> 
  </div>
);

// Main Content Component
const TargetPageContent = () => {
  const [targets, setTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const [isSearching, setIsSearching] = useState(false);


  const handleTargetAdded = (newTarget) => {
    setTargets((prevTargets) => [...prevTargets, newTarget]);
    console.log('Targets after addition:', [...targets, newTarget]); 
};



  const handleTargetUpdated = (updatedTarget) => {
    setTargets(prevTargets => prevTargets.map(target => 
        target.id === updatedTarget.id ? updatedTarget : target
    ));
};


const handleTargetDeleted = (targetId) => {
setTargets((prevTargets) => prevTargets.filter((target) => target.id !== targetId));
  };

  // Toggle Add Popup
  const handleTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Submit Add Popup
  const handlePopupSubmit = (e) => {
    e.preventDefault();
    handleClosePopup();
  };

  // Open Edit Popup
  const handleEditClick = (target) => {
    setSelectedTarget(target);
    setIsEditPopupOpen(true);
  };

  const handleClearSearch = () => {
    setSearchQuery('');  
    setIsSearching(false); 
  };

  // Handle Search Action
  const handleSearches = () => {
    setIsSearching(true);  
  };

  

  // Open Delete Popup
  const handleDeleteClick = (target) => {
    setSelectedTarget(target.target_id);  
    setIsDeletePopupOpen(true);
};


  // Submit Delete Popup
  const handleDeleteSubmit = (id) => {
    setTargets(targets.filter(t => t.target_id !== id));
    handleClosePopup();
};


  // Close Popups
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsEditPopupOpen(false);
    setIsDeletePopupOpen(false);
  };

  // Handle Search Input
  const handleSearch = (value) => {
    setSearchQuery(value);
    setIsSearching(true);
  };

  // Toggle Row Expansion
  const handleRowExpansion = (targetId) => {
    if (expandedRows.includes(targetId)) {
      setExpandedRows(expandedRows.filter(id => id !== targetId));
    } else {
      setExpandedRows([...expandedRows, targetId]);
    }
  };

 
  
  // Fetch Targets Data
  useEffect(() => {
    const fetchTargets = async () => {
      const token = Cookies.get('accessToken');
      try {
const response = await api.get(`${API_URL}/targets/`, );

        if (response.status === 200) {
          setTargets(response.data);
        } else {
          console.log('Error: Response status not 200');
        }
      } catch (error) {
        console.log('Error fetching targets:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTargets();
  }, []);

  // Filtered Targets Based on Search Query
  const filteredTargets = targets.filter(target => {
    const { measure, target_id, value, date_created, starting_date, ending_date } = target;
  
    // Convert to string only if the value exists and is a string
    const lowerCaseMeasure = measure && typeof measure === 'string' ? measure.toLowerCase() : '';
    const lowerCaseTargetId = target_id ? target_id.toString().toLowerCase() : '';
    const lowerCaseValue = value ? value.toString().toLowerCase() : '';
    const lowerCaseDateCreated = date_created && typeof date_created === 'string' ? date_created.toLowerCase() : '';
    const lowerCaseStartingDate = starting_date && typeof starting_date === 'string' ? starting_date.toLowerCase() : '';
    const lowerCaseEndingDate = ending_date && typeof ending_date === 'string' ? ending_date.toLowerCase() : '';
    const lowerCaseQuery = searchQuery.toLowerCase();
    
    // Check if the search query matches any of the fields
    return (
      lowerCaseMeasure.includes(lowerCaseQuery) ||
      lowerCaseTargetId.includes(lowerCaseQuery) ||
      lowerCaseValue.includes(lowerCaseQuery) ||
      lowerCaseDateCreated.includes(lowerCaseQuery) ||
      lowerCaseStartingDate.includes(lowerCaseQuery) ||
      lowerCaseEndingDate.includes(lowerCaseQuery)
    );
  });

  return isLoading ? 'Getting data..' : (
    <div className='target-page dashboard-page'>
      <div className="main-container">
      <div className='buttons-search'>
      <div className='search-button'>
<SearchComponent searchQuery={searchQuery} handleSearch={handleSearch} />
{isSearching
                        ? <SecondaryButton iconClass={<CleanIcon />} buttonText='Clear' onClick={handleClearSearch} />
                        : <PrimaryButton isLoading={false} onClick={handleSearches} processingText='Searching' buttonText='Search' />
                    }
</div>
<PrimaryButton
          isLoading={isSubmitting}
          onClick={handleTogglePopup}
          processingText={'Submitting'}
          iconClass={<PlusSignIcon />}
          buttonText={'Add Target'}
        />
        
        {isPopupOpen && (
          <AddTargetPopup
onClose={handleClosePopup}
onSubmit={handlePopupSubmit}
onTargetAdded={handleTargetAdded}
          />
        )}
      </div>

      

        {filteredTargets.length > 0 ? (
          <div className='targets'>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Measure Name</th>
                  <th>Target</th>
                  <th>Date Created</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isEditPopupOpen && selectedTarget && (
                  <EditTarget
                    targetData={selectedTarget}  
                    onClose={handleClosePopup}
// onSubmit={handleEditSubmit}  
onSubmit={handleTargetUpdated}
                  />
                )}
                {isDeletePopupOpen && (
    <DeleteTargetPopup
        targetId={selectedTarget}  
        onClose={handleClosePopup}
        onDelete={handleDeleteSubmit}
deleteTarget={handleTargetDeleted}
    />
)}

                {filteredTargets.map((target) => (
                  <React.Fragment key={target.target_id}>
                    <tr>
                      <td className='input-check'>
                        <input type='checkbox' />
                        {expandedRows.includes(target.target_id) ? (
                          <ArrowUp01Icon onClick={() => handleRowExpansion(target.target_id)} />
                        ) : (
                          <ArrowDown01Icon onClick={() => handleRowExpansion(target.target_id)} />
                        )}
                      </td>
                      <td>{target.target_id}</td>
                      <td>
                            {target.measure 
                                ? target.measure.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') 
                                : 'N/A'}
                        </td>
{/* <td>{target.measure.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || 'N/A'}</td> */}
                      <td>{(target.value || target.value == 0)?target.value: 0}</td>
                      <td><FormattedDate dateString={target.date_created || 'N/A'} /></td>
                      {/* <td>{target.date_created || 'N/A'}</td> */}
                      <td>
                        <div className='action-button'>
                          <Delete01Icon onClick={() => handleDeleteClick(target)} />
                          <PencilEdit02Icon onClick={() => handleEditClick(target)} />
                        </div>
                      </td>
                    </tr>
                    {expandedRows.includes(target.target_id) && (
                      <tr className='details-row'>
                        <td colSpan="6">
                          <div className="expanded-row">
                            <div className='dates'> 
                              <strong>Start Date:</strong>
                              <div className='icon-date'> 
                                <Calendar03Icon size={18}/>{target.starting_date}
                              </div>
                            </div>
                            <div className='dates'>
                              <strong>End Date:</strong>
                              <div className='icon-date'> 
                              {/* <td><FormattedDate dateString={target.ending_date} /></td> */}
                                <Calendar03Icon size={18}/>{target.ending_date}
                              </div> 
                            </div>                 
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No targets found</p>
        )}
      </div>
    </div>
  );
};

const TargetPage = () => {
  return (
    <DashboardContainer content={<TargetPageContent />} pageTitle={'Targets'} />
  );
};

export default TargetPage;