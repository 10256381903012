import React, { useState, useRef, useEffect } from 'react';
import { TextInput, DataListInput, DateInput } from '../Input';
import { PrimaryButton, SecondaryButton } from '../buttons';
import toast from 'react-hot-toast';
import { Flag01Icon } from "hugeicons-react";
import { API_URL } from '../../../api';
import axios from 'axios';
import Cookies from 'js-cookie';

const NewMeasureForm = ({ isOpen, onClose, onMeasureAdded }) => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [targetValue, setTargetValue] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState(''); 
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [typeOptions] = useState(['numerical', 'percentage']);
    const [isSaving, setIsSaving] = useState(false);
    
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return isNaN(date) ? "" : date.toISOString().split('T')[0];
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/categories/list/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const categoryNames = response.data.map(category => category.name);
                setCategories(categoryNames);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories');
            }
        };

        fetchCategories();
    }, []);

    const handleNewMeasureForm = async (e) => {
        e.preventDefault();

        
        if (!name || !category  || !description) {
            toast.error('Please fill all fields');
            return;
        }

        const newMeasure = {
            name,
            category,
            type,
            description,
        };

        console.log("Sending data: ", newMeasure);

        setIsSaving(true);

        try {
            const token = Cookies.get('accessToken');
            const response = await axios.post(`${API_URL}/measures/new_measure/`, newMeasure, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) {
                toast.success('Measure added successfully');
                if (typeof onMeasureAdded === 'function') {
                    onMeasureAdded(response.data);
                }
                onClose();
                window.location.reload();
            } else {
                toast.error('Unexpected response. Measure may not have been saved.');
            }
        } catch (error) {
            console.error('Error adding measure:', error);
            toast.error(error.response.data.error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <div className={`form ${isOpen ? 'open' : ''}`} ref={popupRef}>
            <h2>New Measure</h2>
            <form onSubmit={handleNewMeasureForm}>
                <TextInput
                    type='text'
                    name='measureName'
                    placeholder='Measure name'
                    value={name}
                    setValue={setName}
                />
                <textarea
                className='text-area-measure'
                    name='description'
                    placeholder='Measure Descriptions'
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                ></textarea>
               
                <div className='value-type'>
                <DataListInput
                    iconClass={<Flag01Icon />}
                    id="category"
                    name="categoryName"
                    placeholder="Category Name"
                    value={category}
                    setValue={setCategory}
                    options={categories}
                />
               
                <DataListInput
                    id="type"
                    name="measureType"
                    placeholder="Select Type"
                    value={type}
                    options={typeOptions} 
                    setValue={setType}
                /> 
                </div>
               
               

                <div className="buttons">
                    <PrimaryButton
                        buttonText='Save'
                        onClick={handleNewMeasureForm}
                        isLoading={isSaving}
                        processingText='Saving'
                    />
                    <SecondaryButton
                        onClick={handleCancel}
                        isLoading={false}
                        buttonText="Cancel"
                    />
                </div>
            </form>
        </div>
    );
};

export default NewMeasureForm;
