import React, { useState } from 'react'
import { DateInput, TextInput } from '../Input'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PrimaryButton } from '../buttons';
import toast from 'react-hot-toast';


const NewReportForm = ({ setShowNewReportFrom }) => {
    const [name, setName] = useState('')
    const [target, setTarget] = useState('')
    const [reportingDate, setReportingDate] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [formMessage, setFormMessage] = useState()

    const handleNewReportForm = () => {

        if (!name || !target || !reportingDate) {
            toast.error('Please fill all fields')
            return
        }
        setIsSaving(true)
        setTimeout(() => {
            setIsSaving(false)
            setShowNewReportFrom(false)
        }, 2000);
    }
    return (
        <div className='form'>
            <h2>New Report</h2>
            <form>
                <TextInput type={'text'} name={"ReportName"} placeholder={'Report name'} value={name} setValue={setName} />
                <TextInput type={'number'} name={"ReportTarget"} placeholder={'Report target'} value={target} setValue={setTarget} />
                <DateInput date={reportingDate} setDate={setReportingDate} choices={["month", 'year']} />
            </form>

            <div className="buttons">
                <PrimaryButton buttonText={'Save'} onClick={handleNewReportForm} isLoading={isSaving} processingText={'Saving'} />

            </div>
        </div>
    )
}

export default NewReportForm
