import React, { useState, useEffect } from 'react';
import api, { API_URL } from '../../api';
import axios from 'axios';
import { UsersList } from '../../data/UsersList';
import '../../assets/css/user/user.css';
import { PrimaryButton, SecondaryButton } from '../../components/forms/buttons';
import { CheckmarkSquare01Icon, Flag01Icon, PlusSignIcon, ArrowUp01Icon, FileExportIcon, ArrowDown01Icon, Edit02Icon, Delete01Icon, Search01Icon, Mail01Icon, UserIcon } from 'hugeicons-react';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import AddUserForm from '../../components/forms/AddUserForm';
import { useNavigate } from 'react-router-dom';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DataListInput, DateInput, TextInput } from '../../components/forms/Input'
import EditUser from '../../components/user/EditUser';
import DeleteUser from '../../components/user/DeleteUser';
import Cookies from 'js-cookie';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRolesPage from '../dashboard/UserRolesPage'; 


const SearchComponent = ({ searchQuery, handleSearch }) => (
    <div className="search-input">
        <TextInput
            iconClass={<Search01Icon />}
            type={'search'}
            placeholder={'Search by measure name'}
            value={searchQuery}
            setValue={handleSearch}
        />
    </div>
);
const UserPageContent = () => {
    const [users, setUsers] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedRow, setExpandedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [roles, setRoles] = useState(["Roles"]);
    const [selectedRoles, setSelectedRoles] = useState('');
    const itemsPerPage = 6;
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [activeTab, setActiveTab] = useState('users');
  const [selectedUserId, setSelectedUserId] = useState(null);

  const navigate = useNavigate();

  const handleUserAdded = (newUser) => {
    setUsers((prevUsers) => [...prevUsers, newUser]);
  };

  const handleUserUpdated = (updatedUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    );
  };

  const handleUserDeleted = (userId) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  };


  const fetchUsers = async () => {
    const token = Cookies.get('accessToken');
    try {
        const response = await axios.get(`${API_URL}/user/list_users/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            console.log('Fetched users:', response.data);
            setUsers(response.data);

            const uniquePermissions = [
                ...new Set(
response.data.map(user => user.position)
                )
            ];
            setRoles(uniquePermissions);
        } else {
            console.error('Error: Response status is not 200');
        }
    } catch (error) {
        console.error('Error fetching users:', error);
    } finally {
        setIsLoading(false);
    }
};

useEffect(() => {
    fetchUsers();
}, []);

    const handleAddNewUser = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setIsEditPopupOpen(false);
        setIsDeletePopupOpen(false);
        fetchUsers();
    };


    const handleRowClicks = (user_id) => {
        navigate(`/users/${user_id}`);
      };
    
      // Handle row expansion for additional details
     
    
    const handleEditSubmit = async (updatedUser) => {
        try {
            setIsSubmitting(true);
            await updatedUser(updatedUser);
            toast.success('User updated successfully');
        } catch (error) {
            console.error('Failed to update user:', error);
            toast.error('Failed to update user');
        } finally {
            setIsSubmitting(false);
            closePopup();
        }
    };

    const handleDeleteClick = (user) => {
        setSelectedUser(user);
        setIsDeletePopupOpen(true);
    };
    const handleClose = () => {
        setIsEditModalOpen(false);
        setSelectedUser(null);
      };
      const handleUpdate = (updatedUserData) => {
        
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === updatedUserData.id ? updatedUserData : user
          )
        );
        handleClose();
      };
    const handleEditUser = (userId) => {
        const user = users.find((user) => user.user_id === userId);
        setSelectedUserId(userId); 
        setSelectedUserData(user);  
        // setIsModalOpen(true);  
        setSelectedUserData(user);
        setIsEditPopupOpen(true);
      };
    

    const deleteUser = (userId) => {
        setUsers(users.filter(user => user.user_id !== userId));
    };

    const addUser = (e) => {
        e.preventDefault();
        closePopup();
    };

    const handleSearch = (value) => {
        setSearchQuery(value);
    };

    const handleRolesChange = (role) => {
        setSelectedRoles(role);
    };

    const filteredUsers = users.filter(user => {
        const lowerCaseUsername = user?.username?.toLowerCase() || '';
        const lowerCaseFirstName = user?.first_name?.toLowerCase() || '';
        const lowerCaseLastName = user?.last_name?.toLowerCase() || '';
        const lowerCaseEmail = user?.email?.toLowerCase() || '';
const lowerCaseDateCreated = user?.date_added?.toLowerCase() || '';
const lowerCaseRoles = user?.position?.toLowerCase() || '';
        const lowerCaseQuery = searchQuery.toLowerCase();
        return (
            lowerCaseUsername.includes(lowerCaseQuery) ||
            lowerCaseFirstName.includes(lowerCaseQuery) ||
            lowerCaseLastName.includes(lowerCaseQuery) ||
            lowerCaseEmail.includes(lowerCaseQuery) ||
            lowerCaseDateCreated.includes(lowerCaseQuery))
            && (selectedRoles === '' || lowerCaseRoles.includes(selectedRoles.toLowerCase()))
    });

    const handleRowClick = (userId) => {
        setExpandedRow(expandedRow === userId ? null : userId);
    };
   


    const handleRowExpansion = (user_id) => {
        setExpandedRow(expandedRow === user_id ? null : user_id);
      };

    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const handlePage = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= Math.ceil(filteredUsers.length / itemsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };

    const handleExport = () => {
        const doc = new jsPDF();
        const tableColumn = ["First Name", "Last Name", "Profile Image", "Username", "User Role"];
        const tableRows = [];
        filteredUsers.forEach(user => {
            const userData = [
                // user.id,
user.first_name,
user.last_name,
                user.username,
user.email,
                // user.permission,
                // formatDate(user.date_added),
            ];
            tableRows.push(userData);
        });
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
        });
        doc.save("users.pdf");
    };


    return isLoading ? (
        <p>Loading...</p>
    ) : (
        <div className="user-content">
            {isPopupOpen && (
                <div className="overlay">
                    <AddUserForm
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                        onUserAdded={handleUserAdded}
                    />
                </div>
            )}

<div className="buttons-search">
<div className="search-users">
                <TextInput 
                    iconClass={<Search01Icon />} 
                    type={'search'} 
                    placeholder={'Search by username'} 
                    value={searchQuery} 
                    setValue={setSearchQuery} 
                />
            </div>
<div className="add-user-bttns">
<div className="export-bttn" onClick={handleExport}>
                    <FileExportIcon size={24} />
                    <p>Export Users</p>
                </div>
                {localStorage.getItem("position") === "Hospital Admin" ? '' : (
                    <div className="primary-btn">
                    <PrimaryButton
                    
                        isLoading={isSubmitting}
                        onClick={handleAddNewUser}
                        processingText={'Submitting'}
                        iconClass={<PlusSignIcon />}
                        buttonText={'Add New User'}
                    />
                    </div>
                )}
               
            </div>

            
</div>
          
            
            <div className="tabs">
                <div
                    className={`tab ${activeTab === 'users' ? 'active' : ''}`}
                    onClick={() => setActiveTab('users')}
                >
                    All Users
                </div>
                {/* <div
                    className={`tab ${activeTab === 'userRoles' ? 'active' : ''}`}
                    onClick={() => setActiveTab('userRoles')}
                >
                    User Roles Definitions
                </div> */}
            </div>

            {activeTab === 'users' && (
                filteredUsers.length > 0 ? (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>ID</th>
                                    <th>Username</th>
                                    <th>
                                        {/* Roles */}
                                        <strong>
                                            <DataListInput
                                                id="permission"
                                                name="permission"
                                                placeholder="Roles"
                                                iconClass={<ArrowDown01Icon />}
                                                options={roles}
                                                value={selectedRoles}
                                                setValue={handleRolesChange}
                                            />
                                        </strong>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {isEditPopupOpen && (
<EditUser userId={selectedUserId} userData={selectedUserData} onClose={closePopup}  onSubmit={handleUserUpdated} />

            )}
                                {isDeletePopupOpen && (
                                    <DeleteUser
                                        isOpen={isDeletePopupOpen}
                                        userId={selectedUser}
                                        onClose={closePopup}
                                        deleteUser={handleUserDeleted}
                                    />
                                )}
                                {filteredUsers.map((user) => (
                                    <React.Fragment key={user.user_id}>
                                        <tr
                                            key={user.user_id}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleRowClicks(user.user_id)}
                                        >
                                            <td className='input-check'>
                                                <input type='checkbox' />
                                                <ArrowDown01Icon
                                                    onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        handleRowClick(user.user_id);
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </td>
                                            <td>{user.account_id}</td>
                                            <td className='td-img'>
                                                <img src={user.profile_image || '/favicon.ico'} alt="" />
                                                {user.username || 'N/A'}
                                            </td>
                                            <td>{user.position}</td>
                                            <td>
                                                <Delete01Icon onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteClick(user.user_id);
                                                }} />
                                                <Edit02Icon onClick={(e) => { 
                                                    e.stopPropagation();
                                                    handleEditUser(user.user_id);
                                                }} />
                                            </td>
                                        </tr>
                                        {expandedRow === user.user_id && (
                                            <tr>
                                                <td colSpan="6">
                                                    <div className="more-userInfo">
                                                        <p><UserIcon /> <strong>First Name:</strong> {user.first_name || 'N/A'}</p>
                                                        <p><UserIcon /> <strong>Last Name:</strong> {user.last_name || 'N/A'}</p>
                                                        <p><Mail01Icon /> <strong>Email:</strong> {user.email || 'N/A'}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        {/* <div className="pagination">
                            <button
                                className='buttons prev-btn'
                                onClick={() => handlePage(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            {Array.from({ length: Math.ceil(filteredUsers.length / itemsPerPage) }, (_, i) => (
                                <button
                                    key={i + 1}
                                    className={`buttons ${currentPage === i + 1 ? 'active' : 'pagination-btn'}`}
                                    onClick={() => handlePage(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                className='buttons next-btn'
                                onClick={() => handlePage(currentPage + 1)}
                                disabled={currentPage === Math.ceil(filteredUsers.length / itemsPerPage)}
                            >
                                Next
                            </button>
                        </div> */}
                    </div>
                ) : (
                    <p>No users found.</p>
                )
            )}

            {activeTab === 'userRoles' && (
                <div>
                    
                    <UserRolesPage />
                </div>
            )}
        </div>
    );
};

const UserPage = () => {
    return (
        <DashboardContainer content={<UserPageContent />} pageTitle={'Users'} />
    );
};

export default UserPage;
