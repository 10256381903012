import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { Loading03Icon } from 'hugeicons-react'
import toast from 'react-hot-toast'
import axios from 'axios'
import { API_URL } from '../../api'
import PasswordResetFrom from '../../components/forms/PasswordResetForm'
const PasswordReset = () => {
    const resetPasswordEmails = Cookies.get('resetPasswordEmails')
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordResetCode, setPasswordResetCode] = useState("")

    const handleVerifyEmail = async () => {

        if (!resetPasswordEmails || !passwordResetCode || !password) {
            toast.error('Please enter a valid email and code ')
            return
        }

        try {
            const credentials = {
                "email": resetPasswordEmails,
                "code": passwordResetCode,
                "password": password
            }
            const response = await axios.post(`${API_URL}/accounts/password-reset/`, credentials)

            if (response.status === 200) {
                Cookies.set('resetPasswordToken', response.data.token)
                toast.success('Email sent successfully. Please check your inbox')
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Something went wrong. Please try again')
            }
            setIsLoading(false)
        }
    }
    return (
        <div className='auth-pages'>
            <div className="page-content">
                <img className='logo' src="/logo192.png" alt="logo" />
                <h2>Reset your password</h2>
                <p>We sent a code the email you provide. Paste it here, type your new password.</p>
                <PasswordResetFrom />
            </div>
        </div>
    )
}

export default PasswordReset
