import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TextInput, DataListInput, DateInput } from '../../Input';
import { PrimaryButton, SecondaryButton } from '../../../../components/forms/buttons';
import toast from 'react-hot-toast';
import { API_URL } from '../../../../api';
import "../../../../assets/css/profile/profile.css";
import { Flag01Icon } from "hugeicons-react";
import dayjs from 'dayjs';

const EditMeasureData = ({ measure_data_id, measureData, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
        hospital: '',
        measure: '',
        value: '',
        starting_date:'',
        end_date:'',
        // reporting_date: '',
        // type: ''
    });

    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    // const [reportingDate, setReportingDate] = useState(null);  
    const [startingDate, setStartingDate] = useState(null);  
    const [endingDate, setEndingDate] = useState(null);  
    const [measureOptions, setMeasureOptions] = useState([]); 
    const [typeOptions] = useState(['numerical', 'percentage']);

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        // Fetch measures when the component mounts
        const fetchMeasures = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/measures/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                // Assuming response.data is an array of measure objects
                setMeasureOptions(response.data); // Adjust based on your API response structure
            } catch (error) {
                console.error('Failed to fetch measures:', error);
                toast.error('Failed to load measures');
            }
        };

        fetchMeasures();

        if (measureData && Object.keys(measureData).length > 0) {
            setFormData({
                hospital: measureData.hospital || '',
                measure: measureData.name || '',
                value: measureData.value || '',
                // type: measureData.type || '',
                // reporting_date: measureData.reporting_date ? dayjs(measureData.reporting_date).format('YYYY-MM-DD') : '' ,
                starting_date: measureData.starting_date ? dayjs(measureData.starting_date).format('YYYY-MM-DD') : '' ,
                end_date: measureData.end_date ? dayjs(measureData.end_date).format('YYYY-MM-DD') : ''   
            });
            // setReportingDate(measureData.reporting_date ? dayjs(measureData.reporting_date) : null); 
            setStartingDate(measureData.starting_date ? dayjs(measureData.starting_date) : null); 
            setEndingDate(measureData.end_date ? dayjs(measureData.end_date) : null); 
        }
    }, [measureData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError('');
        setSuccessMessage('');

        // Check if all required fields are filled
        if (!formData.hospital || !formData.measure || !formData.value || !formData.starting_date || !formData.end_date) {
            setError('All fields are required.');
            setIsSaving(false);
            return;
        }

        try {
            const token = Cookies.get('accessToken');
            const response = await axios.put(
                `${API_URL}/measures/update_measure_data_api/${measure_data_id}/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Measure updated successfully!');
                if (onSubmit) onSubmit();
                onClose();
            } else {
                toast.error('Failed to update measure');
            }
        } catch (error) {
            console.error('API error:', error.response ? error.response.data : error.message);
            toast.error('An error occurred while updating measure data.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <div className="overlay">
            <div className="popup-user" ref={popupRef}>
                <form onSubmit={handleSubmit}>
                    <h2>Edit Measure</h2>
                    {error && <div className="error-message">{error}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}

                    <TextInput
                        id="hospital"
                        name="hospital"
                        placeholder="Hospital"
                        value={formData.hospital}
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, hospital: value }))}
                    />

                    <DataListInput
                        iconClass={<Flag01Icon />}
                        id="measure"
                        name="measureName"
                        placeholder="Measure name"
                        value={formData.measure} 
                        options={measureOptions.map(measure => measure.name)} // Extracting the name property
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, measure: value }))}
                    />

{/* <DataListInput
                        id="type"
                        name="measureType"
                        placeholder="Select Type"
                        value={formData.type}
                        options={typeOptions} 
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, type: value }))}
                    /> */}
                    <TextInput
                        id="value"
                        name="value"
                        placeholder="Value"
                        type="number"
                        value={formData.value}
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, value: value }))}
                    />

                    {/* <DateInput
                        id="reporting_date"
                        name="reporting_date"
                        setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, reporting_date: dayjs(value).format('YYYY-MM-DD') }))}
                        value={reportingDate ? dayjs(reportingDate) : null} 
                        date={reportingDate}
                        setDate={(newDate) => setReportingDate(newDate)}
                        placeholder="Reporting date"
                        label="Reporting date"
                        choices={['day', 'month', 'year']}
                    /> */}
                     <div className="dateInput">
<div className='label-dates'>
<label>Start Date</label>
<div
            onMouseDown={(e) => e.stopPropagation()} // Prevent popup close
            onClick={(e) => e.stopPropagation()} // Prevent popup close
        >
<DateInput
              value={startingDate ? dayjs(startingDate) : null} 
              setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, starting_date: dayjs(value).format('YYYY-MM-DD') }))}
              placeholder={"Start Date"}
              setDate={(newDate) => setStartingDate(newDate)}
              date={startingDate}
              label={"Start Date"}
              choices={["day", "month", "year"]}
            />
            </div>
</div>
          
<div className='label-dates'>
<label>End Date</label>
<div
            onMouseDown={(e) => e.stopPropagation()} // Prevent popup close
            onClick={(e) => e.stopPropagation()} // Prevent popup close
        >
<DateInput
              value={endingDate ? dayjs(endingDate) : null} 
              setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, end_date: dayjs(value).format('YYYY-MM-DD') }))}
              placeholder={"End Date"}
              date={endingDate}
              setDate={(newDate) => setEndingDate(newDate)}
              label={"End Date"}
              choices={["day", "month", "year"]}
            />
            </div>
</div>
            
          </div>

                    <div className="popup-buttons">
                        <SecondaryButton onClick={handleCancel} type="button" buttonText="Cancel" />
                        <PrimaryButton isSubmitting={isSaving} buttonText="Save" type="submit" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditMeasureData;
