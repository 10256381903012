import axios from 'axios';
import { Camera01Icon, Delete01Icon, InformationSquareIcon } from 'hugeicons-react';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../api';
import toast from 'react-hot-toast';
import '../../assets/css/account/account-page.css';
import { PrimaryButton, SecondaryButton } from '../../components/forms/buttons';
import { UserCircleIcon, Mail01Icon } from 'hugeicons-react';
import { DataListInput, TextInput, DateInput } from '../../components/forms/Input';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Cookies from 'js-cookie';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import { useParams } from 'react-router-dom';

const AccountPageContent = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isCancelling, setIsCancel] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [date, setDate] = useState(null);
    const [photo, setPhoto] = useState('');
    const [hospital, setHospital] = useState('');

    // Function to format date as DD-MM-YYYY
    const formatDate = (dateObject) => {
        if (!dateObject) return '';
        return new Intl.DateTimeFormat('en-GB').format(dateObject);
    };

    const handleSave = (e) => {
        e.preventDefault();
        setIsSaving(true);

        // Format the date before saving
        const formattedDate = formatDate(date?.$d);

        setTimeout(() => {
            console.log({ fullName, email, position, formattedDate, hospital, photo });
            toast.success('Form saved successfully!');

            setIsSaving(false);
        }, 2000);
    };

    const handleCancelling = () => {
        setIsCancel(false);
        toast.success('Form canceled successfully!');
        setTimeout(() => {
            setFullName(userData.full_name || '');
            setEmail(userData.email || '');
            setPosition(userData.position || '');
            setHospital(userData.hospital || '');
            setDate(userData.date_created || null);
            setPhoto(userData.photo || '');
        }, 1000);
    };

    const handleDeleteClick = () => {
        setIsPopupOpen(true);
    };

    const confirmDelete = () => {
        setIsPopupOpen(false);
        toast.success('Account deleted successfully!');
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const handlePhotoCapture = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhoto(URL.createObjectURL(file)); // Preview image
        }
    };

    const handleCameraClick = () => {
        document.getElementById('cameraInput').click();
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/user/profile/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const user = response.data;


                setUserData(user);
                setFullName((user.first_name + ' ' + user.last_name) || '');
                setEmail(user.email || '');
                setPosition(user.position || '');
                setHospital(user.hospital || '');
                setDate(user.date_created ? new Date(user.date_created) : null);
                setPhoto(user.photo || '');

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                toast.error('Failed to load user data.');
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);


    return isLoading ? 'Getting Data...' : (
        <div className='account-page'>
            <div className='account-data'>
                <div className="account-contents">
                    <div className="empty-text"></div>
                    {/* <div className="titleName-deleteBtn">
                        <h3>{fullName || 'User Name'}</h3>

                        {
                            localStorage.getItem("position")==="Hospital Admin" ? '' : <SecondaryButton
                            isLoading={isSaving}
                            onClick={handleDeleteClick}
                            processingText={''}
                            iconClass={<Delete01Icon />}
                            buttonText={'Delete Account '}
                        />
                        }
                        
                    </div> */}

                    <div className="profilInfo">
                        <div className="profil-image-text">
                            <h3>Profile Picture</h3>
                            <div className="profil-img">
<img src={photo || "/favicon.ico"} alt="Profile" />
                                {/* <Camera01Icon
                                    className="camera-icon"
                                    onClick={handleCameraClick}
                                /> */}
                                <input
                                    id="cameraInput"
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    style={{ display: 'none' }}
                                    onChange={handlePhotoCapture}
                                />
                            </div>
                        </div>

                        <div className="infoForm">
                            <h3>Information</h3>
                            <form className='account-form' onSubmit={handleSave}>
                                <div className="full-field">
                                    <TextInput
                                        label={'Full name'}
                                        // iconClass={<UserCircleIcon />}
                                        id={'fullName'}
                                        name={'fullName'}
                                        placeholder={"Enter a name"}
                                        value={fullName}
                                        setValue={setFullName}
                                    
disabled={true}
                                    />
                                    <TextInput
                                        label={'Position'}
                                        // iconClass={<UserCircleIcon />}
                                        id={'position'}
                                        name={'position'}
                                        placeholder={"Enter a name"}
                                        value={position}
                                        setValue={setPosition}
                                        disabled={true}
                                    />
                                </div>

                                <div className="field-account">
                                    <div className="hospital-select">

                                        <TextInput
                                            label={'Hospital'}
                                            // iconClass={<UserCircleIcon />}
                                            id={'hospitals'}
                                            name={'hospitals'}
                                            placeholder={"Hospital"}
                                            value={hospital}
                                            setValue={setHospital}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="email">
                                        <TextInput
                                            type={'email'}
                                            label={'Email'}
                                            // iconClass={<Mail01Icon />}
                                            id={'email'}
                                            name={'email'}
                                            placeholder={"Enter your email"}
                                            value={email}
                                            disabled={true}
                                            setValue={setEmail}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <div className="popup overlay">
                    <div className="popup-content">
                        <div className="popup-text-icon">
                            {<InformationSquareIcon className="info-icon" />}
                            <div className="popup-delete-text">
                                <h3>Delete</h3>
                                <p>Do you really want to delete your account?</p>
                            </div>
                        </div>

                        <div className="buttons">
                            <SecondaryButton onClick={closePopup} buttonText={'Cancel'} />
                            <PrimaryButton onClick={confirmDelete} buttonText={'Delete'} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const AccountPage = () => {
    return (
        <DashboardContainer content={<AccountPageContent />} pageTitle={'Account'} />
    );
};

export default AccountPage;
