import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Change to useNavigate
import api, { API_URL } from '../../api';
import PositionCard from '../../components/position/PositionCard';
import { PlusSignIcon } from 'hugeicons-react';
import "../../assets/css/position/position.css";
import { PrimaryButton } from '../../components/forms/buttons';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import FormattedDate from '../../services/formatDate';
import AddPositionPopup from '../../components/positionPopup/AddPositionPopup';
import EditPositionPopup from '../../components/positionPopup/EditPositionPopup';
import toast from 'react-hot-toast';
import DeletePositionPopup from '../../components/positionPopup/DeletePositonPopup';
import ViewPositionDetailsPopup from '../../components/positionPopup/ViewPositionDetailsPopup';


const PositionPageContent = () => {
    const [positions, setPositions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [editPosition, setEditPosition] = useState(null);
    const [deletePositionId, setDeletePositionId] = useState(null); 
    const [viewPositionId, setViewPositionId] = useState(null); 
    const [permissionsData,setPermissionsData] = useState([])
    const navigate = useNavigate(); // Use navigate instead of history

    const fetchPositions = async () => {
        try {
            const response = await api.get(`${API_URL}/position/`);
            if (response.status === 200) {
                setPositions(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Failed to fetch positions:', error);
            setIsLoading(false);
        }
    };

    const handleAddPositionSubmit = async () => {
        await fetchPositions(); 
        setIsPopupVisible(false);
    };

    const handleEditPositionSubmit = async (updatedPosition) => {
        setPositions((prevPositions) =>
            prevPositions.map((pos) =>
                pos.id === updatedPosition.id
                    ? { ...pos, ...updatedPosition }
                    : pos
            )
        );
        await fetchPositions(); 
        setIsPopupVisible(false);
    };

    const handleDeletePosition = async (positionId) => {
        try {
        
            const response = await api.delete(`${API_URL}/position/delete_position/${positionId}/`);
            if (response.status === 204 || response.status === 200) {
                toast.success("Position deleted successfully.");
              
                await fetchPositions();
                navigate('/user-roles'); 
            } else {
                toast.error(`Failed to delete position. Status code: ${response.status}`);
            }
        } catch (error) {
            console.error("Error deleting position:", error);
            toast.error("Error deleting position.");
        }
    };

    const handleAddPosition = () => {
        setEditPosition(null);
        setIsPopupVisible(true);
    };

    const handleEditPosition = (position) => {
        setEditPosition(position);
        setIsPopupVisible(true);
    };

    const handleViewPosition = (position) => {
        setViewPositionId(position.id);
        setPermissionsData(position);

        console.log("permissions");
        console.log(position);
        
        
    };
    const handleClosePopup = () => {
        setIsPopupVisible(false);
        setEditPosition(null);
    };

    useEffect(() => {
        fetchPositions();
    }, []);

    return isLoading ? 'Getting data..' : (
        <div className='position-page'>
        
            {isPopupVisible && (
                editPosition ? (
                    <EditPositionPopup
                        position={editPosition}
                        onClose={handleClosePopup}
                        onSubmit={handleEditPositionSubmit}
                        fetchPositions={fetchPositions}
                    />
                ) : (
                    <AddPositionPopup
                        onClose={handleClosePopup}
                        onSubmit={handleAddPositionSubmit}
                    />
                )
            )}
            {deletePositionId && permissionsData && (
                <DeletePositionPopup
                    positionId={deletePositionId}
                    onClose={() => setDeletePositionId(null)} 
                    onSubmit={handleDeletePosition} 
                />
            )}
            {viewPositionId && (
    <ViewPositionDetailsPopup
        positionId={viewPositionId}
        onClose={() => setViewPositionId(null)} 
        permissions={permissionsData}
    />
)}
            {/* {
                localStorage.getItem("position") !== "Hospital Admin" && (
                    <PrimaryButton
                        isLoading={false}
                        onClick={handleAddPosition}
                        processingText={'Submitting'}
                        iconClass={<PlusSignIcon />}
                        buttonText={'Add a position'}
                    />
                )
            } */}
            {/* <h1>Positions</h1> */}
            <h3>This card lists the available positions and their corresponding role descriptions. By clicking on the dots, you can view detailed information about each position, allowing you to review the role before assigning it to a user.</h3>            
            {positions.length > 0 ? (
                <div className="position">
                    {positions.map((pos) => (
                        <PositionCard
                    key={pos.id}
                    id={pos.position_id}
                    name={pos.name}
                    description={pos.description}
                    dateCreated={<FormattedDate dateString={pos.date_created} />}
                    onEditClick={() => handleEditPosition(pos)}
                    onDeleteClick={() => setDeletePositionId(pos.id)}
                    onView={() => handleViewPosition(pos)}
                />
                    ))}
                    {viewPositionId && (
                <ViewPositionDetailsPopup
                    positionId={viewPositionId}
                    onClose={() => setViewPositionId(null)}
                    permissions={permissionsData}
                />
            )}

                </div>
            ) : (
                'No data available'
            )}
        </div>
    );
};

const PositionPage = () => {
    return (
        <DashboardContainer content={<PositionPageContent />} pageTitle={'Positions'} />
    );
};

export default PositionPage;
