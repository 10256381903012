import { Flag01Icon } from 'hugeicons-react';
import React, { useState, useEffect, useRef } from 'react';
import { DataListInput, DateInput, TextInput } from '../forms/Input';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { API_URL } from '../../api';

const AddTargetPopup = ({ isOpen, onClose, onTargetAdded }) => {
    const [country, setCountry] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [targetValue, setTargetValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [measures, setMeasures] = useState([]);
    const popupRef = useRef(null);
    const [targetType, setTargetType] = useState('');
    const [targetValues, setTargetValues] = useState('');
    const typeOptions = ['numerical', 'percentage'];
    const valuesOptions = ['Lower is better', 'Greater is better', 'N/A'];
   

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const formatDate = (dateString) => {
        if (!dateString) return ""; 
        const date = new Date(dateString);
        if (isNaN(date)) return ""; 
        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        const fetchMeasures = async () => {
            const token = Cookies.get('accessToken');
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/measures/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = response.data;
                const measuresArray = data.map(item => item['name']);
                setMeasures(measuresArray);
            } catch (error) {
                console.error('Error fetching measures:', error);
                toast.error('Failed to load measures');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMeasures();
    }, []);

    const handleDatePickerClick = (e) => {
        e.stopPropagation(); // Prevent closing popup when interacting with DatePicker
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;

        if (!country || !targetValue || !startDate || !endDate || !targetType || !targetValues) {
            toast.error('Please fill in all fields.');
            return;
        }

        const newTarget = {
            measure: country,
            value: targetValue,
            type: targetType,
            value_condition: targetValues,
            starting_date: formatDate(startDate),
            ending_date: formatDate(endDate),
        };

        setIsSubmitting(true);
        try {
            const token = Cookies.get('accessToken');
            const response = await axios.post(`${API_URL}/targets/new_target/`, newTarget, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200 || response.status === 201) {
                const createdTarget = response.data;
                toast.success("Target successfully added");
                onTargetAdded(createdTarget);
                onClose();
                window.location.href = '/targets/';
            }
            else {
                throw new Error("Unexpected response. Target may not have been saved.");
            }
        } catch (error) {
            console.error('Failed to add target:', error);
            toast.error('Error while adding target: ' + (error.response?.data?.detail || 'No response received from server'));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='popup-overlay'>
            <form className='add-target-popup' onSubmit={handleSubmit} ref={popupRef}>
                <h1 className='title'>Add Target</h1>
                <DataListInput
                    iconClass={<Flag01Icon />}
                    id="measures"
                    name="measures"
                    placeholder="Measure Name"
                    value={country}
                    setValue={setCountry}
                    options={measures}
                />
                <TextInput
                    type='text'
                    id='targetValue'
                    name='targetValue'
                    placeholder="Target value"
                    value={targetValue}
                    setValue={setTargetValue}
                />
               <DataListInput
                    label="Type"
                    name="type"
                    value={targetType}
                    setValue={setTargetType}
                    options={typeOptions}
                />
                <DataListInput
                    label="Values"
                    name="values"
                    value={targetValues}
                    setValue={setTargetValues}
                    options={valuesOptions}
                />
              <div className='dateInput'>
    <div className='label-dates'>
        <label>Start Date</label>
        <div
            onMouseDown={(e) => e.stopPropagation()} // Prevent popup close
            onClick={(e) => e.stopPropagation()} // Prevent popup close
        >
            <DateInput
                date={startDate}
                setDate={setStartDate}
                placeholder="Start date"
                label="Start date"
                choices={['day', 'month', 'year']}
            />
        </div>
    </div>
    <div className='label-dates'>
        <label>End Date</label>
        <div
            onMouseDown={(e) => e.stopPropagation()} // Prevent popup close
            onClick={(e) => e.stopPropagation()} // Prevent popup close
        >
            <DateInput
                date={endDate}
                setDate={setEndDate}
                placeholder="End date"
                label="End date"
                choices={['day', 'month', 'year']}
            />
        </div>
    </div>
</div>

                <div className='buttons'>
                    <SecondaryButton
                        onClick={onClose}
                        buttonText='Cancel'
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        type='submit'
                        processingText='Submitting'
                        buttonText='Save'
                    />
                </div>
            </form>
        </div>
    );
};

export default AddTargetPopup;
