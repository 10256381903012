import React from "react";

const DashboardTotalCard = ({ icon, value, name, id }) => {
  return (
    <div className="total-card" id={id}>
      <div className="icon-container">{icon}</div>
      <div className="col">
        <p>{name}</p>
        <div>{value}</div>
      </div>
    </div>
  );
};

export default DashboardTotalCard;
