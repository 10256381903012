import { AlertDiamondIcon } from "hugeicons-react";
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { API_URL } from "../../api";
import { PrimaryButton, SecondaryButton } from "../forms/buttons";
import Cookies from "js-cookie";
import toast from 'react-hot-toast';

const DeletePositionPopup = ({ positionId, onClose, onSubmit,fetchPositions }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!positionId) {
            toast.error("Position ID is not defined.");
            return;
        }

        setIsSubmitting(true);
        const token = Cookies.get("accessToken"); 
        try {
            const response = await axios.delete(`${API_URL}/position/delete_position/${positionId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 204 || response.status === 200) {
                toast.success("Position deleted successfully.");
                // onSubmit(positionId); 
                // onClose();
                await fetchPositions();
            } else {
                toast.error(`Failed to delete the position. Status code: ${response.status}`);
            }
        } catch (error) {
            console.error("Error deleting position:", error);
            // toast.error("Error deleting position.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="popup-overlay">
            <form className="delete-position-popup" ref={popupRef} onSubmit={handleSubmit}>
                <div className="icon-text">
                    <AlertDiamondIcon className="icon" />
                    <div className="text">
                        <h4>Delete</h4>
                        <p>Do you really want to delete the Role with ID {positionId}?</p>
                    </div>
                </div>
                <div className="buttons">
                    <SecondaryButton onClick={onClose} buttonText={"Cancel"} />
                    <PrimaryButton isLoading={isSubmitting} processingText={"Deleting"} buttonText={"Delete"} />
                </div>
            </form>
        </div>
    );
};

export default DeletePositionPopup;
