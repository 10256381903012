import React, { useState } from "react";
import { PrimaryButton } from "../../components/forms/buttons";
import { PlusSignIcon, Delete01Icon } from "hugeicons-react";
import DashboardContainer from "../../components/dashboard/DashboardContainer";
import NewNotificationForm from "../../components/forms/notification/NewNotificationForm";
import toast from "react-hot-toast";

// Updated table component with checkbox for selecting notifications
const NotificationsTable = ({ Notifications, selectedNotifications, handleSelect }) => {
  return (
    <div className="table-container">
      <table className="Notifications-table">
        <tbody>
          {Notifications.length > 0 ? (
            Notifications.map((Notification, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedNotifications.includes(index)}
                    onChange={() => handleSelect(index)}
                  />
                </td>
                <td>{Notification.content}</td>
                <td>{Notification.time}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ textAlign: "center" }}>
                No Notifications found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const NotificationPageContent = () => {
  const [showNewNotificationForm, setShowNewNotificationForm] = useState(false);
  const [Notifications, setNotifications] = useState([
    {
      content: "Amour Cyusa Bahizi has updated a measure performance of ED Mortality Rate",
      time: "1 hour",
    },
    {
      content: "Your January month summary Notification is here",
      time: "3 hours",
    },
    {
      content: "Amour Cyusa Bahizi has updated a measure performance of ED Mortality Rate",
      time: "2 days",
    },
    {
      content: "Amour Cyusa Bahizi has updated a measure performance of ED Mortality Rate",
      time: "24 hours",
    },
    {
      content: "Amour Cyusa Bahizi has updated a measure performance of ED Mortality Rate",
      time: "24 hours",
    },
    {
      content: "Amour Cyusa Bahizi has updated a measure performance of ED Mortality Rate",
      time: "24 hours",
    },
  ]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  const handleSelect = (index) => {
    if (selectedNotifications.includes(index)) {
      setSelectedNotifications(
        selectedNotifications.filter((i) => i !== index)
      );
    } else {
      setSelectedNotifications([...selectedNotifications, index]);
    }
  };

  const handleDelete = () => {
    if (selectedNotifications.length === 0) {
      toast.error("Please select notifications to delete");
      return;
    }

    const filteredNotifications = Notifications.filter(
      (item, index) => !selectedNotifications.includes(index)
    );
    setNotifications(filteredNotifications);
    setSelectedNotifications([]); // Clear selected notifications after deletion
    toast.success("Selected notifications deleted");
  };

  return (
    <div className="Notifications-page">
      {showNewNotificationForm && (
        <div className="popup new-Notification-form-popup">
          <div className="popup-content">
            <NewNotificationForm setShowNewNotificationForm={setShowNewNotificationForm} />
          </div>
        </div>
      )}

      <div className="action" style={{ marginBottom: "20px" }}>
        <PrimaryButton
          iconClass={
            <Delete01Icon size={20} color={"#4a90e2"} variant={"stroke"} />
          }
          buttonText={"Delete"}
          onClick={handleDelete} // Trigger delete function
        />
      </div>

      <div className="Notifications">
        <NotificationsTable
          Notifications={Notifications}
          selectedNotifications={selectedNotifications}
          handleSelect={handleSelect}
        />
      </div>
    </div>
  );
};

const NotificationPage = () => {
  return (
    <DashboardContainer content={<NotificationPageContent />} pageTitle={"Notifications"} />
  );
};

export default NotificationPage;
