import React from 'react';
import { PieChart } from '@mui/x-charts';

function SinglePagePieChart({ PieChartData,legendPieChartData }) {

  const pieChartData = [];
  
  const colors = ['#07AEEF', '#FF5733', '#28A745']; 
  let colorIndex = 0;
  
  for (const data in PieChartData) {
  
    if (PieChartData[data] > 0) {
      pieChartData.push({
        label: data,
        value: PieChartData[data],
        color: colors[colorIndex % colors.length], 
      });
      colorIndex++;
    }
  }

  const totalValue = pieChartData.reduce((sum, item) => sum + item.value, 0); 

  const data = pieChartData.length > 0 ? pieChartData : [{ label: 'No Data77', value: 1, color: '#FF5733' }];

  return (
    <>
    <div className="statistics">
        <div className="title">
            <p>Statistics</p>
            <h3>
            Falls by Injury Type</h3>
        </div>
        <div className="divider"></div>
        <div className="pie-chart" >
      <PieChart
        series={[
          {
            data,
            innerRadius: 50,
            outerRadius: 80,
            cornerRadius: 0,
            startAngle: 0,
          },
        ]}
        height={200}
        title="Falls by Injury Type"
        legend={{ hidden: true }}
        colors={data.map(item => item.color)} 
      />

{/* <CustomCenterLabel label="Totals" number={pieChartData.total} /> */}
<CustomLegend data={legendPieChartData} />

        </div>
    </div>
    </>
  );
}

export default SinglePagePieChart;


const CustomCenterLabel = ({ label, number }) => {
  return (
    <div className='center-label'>
      <div className='label'>{label}</div>
      <div className='number'>{number}</div>
    </div>
  );
};

function capitalizeFirstLetter(word) {
  if (!word) return ''; 
  return word;
}

const CustomLegend = ({ data }) => {

  console.log("data custom legend")
  console.log(data[0])
  // console.log(data[0].value)
  // if (data[0].value === undefined){
  //   console.log("hi")
  // }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px',  }}>
      {data.map((item, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{
            display: 'inline-block',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: item.color
          }}></span>
          <span style={{ color: '#999999'}}>{capitalizeFirstLetter(item.label)}</span>
          <span style={{ fontWeight: '600'}}>{item.value}%</span>
         
        </div>
      ))}
    </div>
  );
};


