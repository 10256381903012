import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../api';
import { PrimaryButton, SecondaryButton } from "../../components/forms/buttons";
import { TextInput } from '../forms/Input';

const EditHospitalPopup = ({ hospital, onClose, onUpdateHospital, fetchHospitals }) => {
  const [formData, setFormData] = useState({
    name: hospital?.name || '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const popupRef = useRef(null);


  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose(); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEditHospital = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const token = Cookies.get('accessToken');
    try {
      const response = await axios.put(
`${API_URL}/hospitals/update_hospital/${hospital.id}/`, 
        formData,
        
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      

      
      if (response.status === 200) {
        setSuccessMessage('Hospital updated successfully!');
        await fetchHospitals(); 
        onClose(); 
      }
    } catch (error) {
      setError('Error updating hospital.');
      console.error('Error updating hospital:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="popup popup-overlay">
      <div className="add-target-popup" ref={popupRef}>
        <h3>Edit Hospital</h3>
        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <form onSubmit={handleEditHospital}>
        <TextInput
  id="name"
  name="name"
  placeholder="Hospital Name"
  value={formData.name}
  setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, name: value }))}
/>

          <div className="action-btn">
            <PrimaryButton isLoading={isSubmitting} buttonText="Update Hospital" type="submit" />
            <SecondaryButton onClick={onClose} buttonText="Cancel" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditHospitalPopup;
