import React, { useEffect, useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from "../../api";
import { PrimaryButton } from "../../components/forms/buttons";
import DashboardContainer from "../../components/dashboard/DashboardContainer";
import {Delete01Icon, Edit02Icon, PlusSignIcon} from "hugeicons-react";
import AddHospitalListPopup from "../../components/hospitals/AddHospitalListPopup ";
import EditHospitalPopup from "../../components/hospitals/EditHospitalPopup";
import DeleteHospitalPopup from "../../components/hospitals/DeleteHospitalPopup";


const HospitalsContent = () => {
  const [hospitals, setHospitals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState(null);
const [editPopupOpen, setEditPopupOpen] = useState(false);
const [deletePopupOpen, setDeletePopupOpen] = useState(false);


  const fetchHospitals = async () => {
    const token = Cookies.get('accessToken');
    try {
      const response = await axios.get(`${API_URL}/hospitals/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setHospitals(response.data);
      }
    } catch (error) {
      console.error('Error fetching hospitals:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHospitals();
  }, []);

  const handleAddHospital = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleAddHospitalToList = (newHospital) => {
    setHospitals((prevHospitals) => [...prevHospitals, newHospital])
  };
  const handleEditHospitalToList = (updatedHospital) => {
    setHospitals((prevHospitals) =>
      prevHospitals.map((h) => (h.id === updatedHospital.id ? updatedHospital : h))
    );
  };
  
  
  const handleDeleteHospitalFromList = (hospitalId) => {
    setHospitals((prevHospitals) => prevHospitals.filter((h) => h.id !== hospitalId));
  };
  
  return isLoading ? (
    <p>Loading...</p>
  ) : (
<div className="hospital-content">
      <div className='select-button'>
        <PrimaryButton
          onClick={handleAddHospital}
          iconClass={<PlusSignIcon />}
          buttonText={'Add Hospital'}
        />
        {isPopupOpen && (
<AddHospitalListPopup 
            onClose={handleClosePopup}
            onAddHospital={handleAddHospitalToList} 
          />
        )}
      </div>
      {hospitals.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {editPopupOpen && (
      <EditHospitalPopup 
        hospital={selectedHospital} 
        onClose={() => setEditPopupOpen(false)} 
        onUpdateHospital={handleEditHospitalToList} 
        fetchHospitals={fetchHospitals}
      />
    )}

    {deletePopupOpen && (
  <DeleteHospitalPopup 
    hospital={selectedHospital} 
    onClose={() => setDeletePopupOpen(false)} 
    onDeleteHospital={handleDeleteHospitalFromList} 
  />
)}


            {hospitals.map((hospital) => (
              <tr key={hospital.id}>
                <td>{hospital.id}</td>
                <td>{hospital.name}</td>
                <td>{new Date(hospital.date_created).toLocaleDateString()}</td>
                <td>
                <Edit02Icon onClick={() => { setSelectedHospital(hospital); setEditPopupOpen(true); }} />
                <Delete01Icon onClick={() => { setSelectedHospital(hospital); setDeletePopupOpen(true); }} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No hospitals found.</p>
      )}
    </div>
  );
};
export default HospitalsContent;


