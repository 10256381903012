
import React, { useState, useEffect, useRef } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/forms/buttons';
import { CleanIcon, Edit02Icon, Delete01Icon, PlusSignIcon, Search01Icon } from 'hugeicons-react';
import { TextInput } from '../../../components/forms/Input';
import api, { API_URL } from '../../../api';
import toast from 'react-hot-toast';
import FormattedDate from '../../../services/formatDate';
import AddNewMeasureData from '../../../components/forms/measures/MeasureData/AddNewMeasureData';
import EditMeasureData from '../../../components/forms/measures/MeasureData/EditMeasureData';
import DeleteMeasureData from '../../../components/forms/measures/MeasureData/DeleteMeasureData';
import '../../../assets/css/pages/measures/measures.css';
import DashboardContainer from '../../../components/dashboard/DashboardContainer';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const MeasuresTable = ({ measures = [], onEditClick, onDeleteClick }) => {
    const [selectedMeasures, setSelectedMeasures] = useState([]);

    const handleSelectMeasure = (measureId) => {
        const index = selectedMeasures.indexOf(measureId);
        if (index === -1) {
            setSelectedMeasures([...selectedMeasures, measureId]);
        } else {
            setSelectedMeasures(selectedMeasures.filter((id) => id !== measureId));
        }
    };

    return measures.length < 1 ? '..Nothing found' : (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Measure ID</th>
                        <th>Measure Name</th>
                        <th>Value</th>
                        {/* <th>Date Created</th> */}
                        <th>Reporting Period Range</th>
                        <th>Hospital</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {measures.map((measure) => (
                        <tr key={measure.measure_data_id}>
                            <td><input onChange={() => handleSelectMeasure(measure.measure_data_id)} type="checkbox" /></td>
                            <td>{measure.synchronous_id}</td>
                            {/* <td>{measure.measure_id}</td> */}
                            <td>{measure.name}</td>
                            <td>{measure.value}</td>
                            {/* <td><FormattedDate dateString={measure.date_created} /></td> */}
                            <td style={{ whiteSpace: 'nowrap', display: "flex", gap: 8, padding: 14 }}>
                                <FormattedDate dateString={measure.starting_date} />  -

                                <FormattedDate dateString={measure.end_date} />

                            </td>
                            <td>{measure.hospital || 'N/A'}</td>
                            <td>
                                <Delete01Icon onClick={() => onDeleteClick(measure.measure_data_id)} />
                                <Edit02Icon onClick={() => onEditClick(measure.measure_data_id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const MeasureDataContent = () => {
    const [measuresData, setMeasuresData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showNewMeasureForm, setShowNewMeasureForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(``);
    const [constMeasureData, setConstMeasureData] = useState([]);
    const itemsPerPage = 10;
    const debounceTimeout = useRef(null);
    const [hasSearched, setHasSearched] = useState(false);
    const [result, setResult] = useState(null);
    const [editMeasureData, setEditMeasureData] = useState(null);
    const [deleteMeasureData, setDeleteMeasureData] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [nextPage, setNextPage] = useState(null)
    const [previousPage, setPreviousPage] = useState(null)
    // const [totalPages, setTotalPages] = useState(1);
    
    const [currentDateYear, setCurrentDateYear] = useState(new Date().getFullYear());
    const [prevYear, setPrevYear] = useState(new Date().getFullYear() - 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [sortingOrder,setSortingOrder] = useState();
    const [filteredData, setFilteredData] = useState();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(searchQuery);
        }
    };
    const handleEditClick = (measure_data_id) => {
        const selectedMeasure = measuresData.find(measure => measure.measure_data_id === measure_data_id);
        setEditMeasureData(selectedMeasure);
    };
    const handleDeleteClick = (measure_data_id) => {
        setDeleteMeasureData(measure_data_id);
    };

    const handleDurationYearChange = (value) => {
        setSelectedYear(value)
      };
    const handleSearch = async (query) => {
        setIsSearching(true);
        if (!query) {
            setMeasuresData(constMeasureData);
            setIsSearching(false);
            setHasSearched(false);
            return;
        }
        try {
            const response = await api.get(`${API_URL}/measures/search/`, {
                params: { measure_name: query }
            });
           
            const sortedMeasures = response.data["results"].sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
            setMeasuresData(sortedMeasures);
            setSortedData(sortedMeasures);
            setHasSearched(true);
            setNextPage(response.data["next"])
            setPreviousPage(response.data["previous"])
        } catch (err) {
            console.error('Error fetching data:', err);
        }
        setIsSearching(false);
    };

    const handleInputChange = (value) => {
        setSearchQuery(value);
        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            handleSearch(value);
        }, 300);
    };
    // Handle sorting by recent or oldest
    const filterByRecent = (condition) => {
        let dataToSort = [...filteredData];

        if (condition === 'recent') {
            dataToSort.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
        } else if (condition === 'oldest') {
            dataToSort.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        }

        setSortedData(dataToSort);
    };


    const handleSortingChange = (order) => {
        setSortingOrder(order);
        fetchMeasures();
    };


    const fetchMeasures = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`${API_URL}/measures/measures_hospital_list/`
                ,
                   { params: { measure_year: selectedYear }}
            );
           
            const sortedMeasures = response.data["results"].sort((a, b) => new Date(a.date_created) - new Date(b.date_created));


            const next = response.data["next"]
            const previous = response.data["previous"]

            setMeasuresData(sortedMeasures);
            setSortedData(sortedMeasures);
            setConstMeasureData(sortedMeasures);
            setMeasuresData(response.data["results"]);
            setNextPage(next)
            setPreviousPage(previous)
            setTotalItems(response.data.count);
        } catch (error) {
            console.error('Error fetching measures:', error);
        }
        setIsLoading(false);
    };

    const changePage = async (url) => {
        try {
            const response = await api.get(`${url}`);

            const sortedMeasures = response.data["results"].sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
            setMeasuresData(sortedMeasures);
            setSortedData(sortedMeasures);
            setHasSearched(true);
            setNextPage(response.data["next"])
            setPreviousPage(response.data["previous"])
        } catch (err) {
            console.error('Error fetching data:', err);
        }
        setIsSearching(false);
    }



    useEffect(() => {
        fetchMeasures();
        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [selectedYear]);

    const handleClearSearch = () => {
        setSearchQuery('');
        setMeasuresData(constMeasureData);
        setHasSearched(false);

    };

    const handleNextPage = (api) => {

        setCurrentPage(nextPage);

        changePage()


    };

    const handlePreviousPage = () => {

        setCurrentPage(previousPage);
        changePage()
      
    };

    // Calculate total pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return isLoading ? 'Loading data...' : (
        <div className='measures-page'>
            {showNewMeasureForm && (
                <div className="popup new-measure-form-popup">
                    <div className="popup-content">
                        <AddNewMeasureData
                            setShowNewMeasureFrom={setShowNewMeasureForm}
                            onClose={() => setShowNewMeasureForm(false)}
                        />
                    </div>
                </div>
            )}
            {editMeasureData && (
                <div className="popup edit-measure-data-popup">
                    <div className="popup-content">
                        <EditMeasureData
                            measure_data_id={editMeasureData.measure_data_id}
                            measureData={editMeasureData}
                            onClose={() => setEditMeasureData(null)}
                            onSubmit={fetchMeasures}
                        />
                    </div>
                </div>
            )}

            {
                deleteMeasureData && (
                    <div className="popup delete-measure-data-popup">
                        <div className="popup-content">
                            <DeleteMeasureData
                                measureId={deleteMeasureData}
                                isOpen={!!deleteMeasureData}
                                onClose={() => setDeleteMeasureData(null)}
                                removeMeasure={fetchMeasures}
                            />
                        </div>
                    </div>
                )
            }

            <div className="search-filter">
                <div className="filters">
                    <TextInput
                        iconClass={<Search01Icon />}
                        type='search'
                        placeholder='Search by measure name'
                        value={searchQuery}
                        setValue={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />

                    <PrimaryButton
                        isLoading={isSearching}
                        onClick={() => handleSearch(searchQuery)}
                        buttonText={isSearching ? 'Searching...' : 'Search'}
                    />
                    {hasSearched && (
                        <SecondaryButton
                            iconClass={<CleanIcon />}
                            buttonText='Clear'
                            onClick={handleClearSearch}
                        />
                    )}

<Select
          onChange={(e) => {
            handleDurationYearChange(e.target.value);
          }}
          name="duration"
          id="duration"
          value={selectedYear}
        >
          <MenuItem value={currentDateYear}>{currentDateYear}</MenuItem>
          <MenuItem value={prevYear}>{prevYear}</MenuItem>
          {/* <MenuItem value={prevYearMinusTwo}>{prevYearMinusTwo}</MenuItem> */}
          {/* <MenuItem value={prevYearMinusThree}>{prevYearMinusThree}</MenuItem> */}
        </Select>
                </div>
                <div className="action">
                    <PrimaryButton iconClass={<PlusSignIcon />} buttonText='New measure data' onClick={() => setShowNewMeasureForm(true)} />
                </div>
            </div>

            <div className="measures">
                <MeasuresTable
                    measures={measuresData}
                    // measures={displayedMeasures}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                />
            </div>
            

            <div className="pagination">
                <button
                    className='buttons prev-btn'
                    onClick={() => changePage(previousPage)}
                    disabled={previousPage === null}
                >
                    Previous
                </button>

                {/* <span>Page {currentPage} of {totalPages}</span>  */}

                <button
                    className='buttons next-btn'
                    onClick={() => changePage(nextPage)}
                    disabled={nextPage === null}
                >
                    Next
                </button>

            </div>


        </div>
    );
};

const MeasureDataPage = () => {
    return (
        <DashboardContainer content={<MeasureDataContent />} pageTitle={'Measured data'} />
    );
};

export default MeasureDataPage;
