import React from 'react'

function HospitalCard({ acute, swing, observation, emergency }) {
    return (
        <div className='hospital-card'>
           <div className="hospital-individual">
            <div className="hos-icon">
                <i></i>
            </div>
            <p>Total Acute</p>
            <h2>{acute}</h2>
           </div>
           <div className="hospital-individual">
           <div className="hos-icon">
                <i></i>
            </div>
            <p>Total Swing Bed</p>
            <h2>{swing}</h2>
           </div>
           <div className="hospital-individual">
           <div className="hos-icon">
                <i></i>
            </div>
            <p>Total Observation</p>
            <h2>{observation}</h2>
           </div>
           <div className="hospital-individual">
           <div className="hos-icon">
                <i></i>
            </div>
            <p>Total Emergency Room</p>
            <h2>{emergency}</h2>
           </div>
        </div>
    )
}

export default HospitalCard
