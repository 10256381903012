import React, { useState ,useRef, useEffect} from 'react';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API_URL } from '../../../../api';
import { Flag01Icon } from 'hugeicons-react';
import toast from 'react-hot-toast';

const DeleteMeasureData = ({ measureId, isOpen, onClose, removeMeasure }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Delete button clicked');
        setIsSubmitting(true);
        const token = Cookies.get('accessToken');
    
        try {
            const response = await axios.delete(`${API_URL}/measures/delete_measure_data_api/${measureId}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
if (response.status === 200) {
                console.log('Measure deleted successfully');
                toast.success('Measure deleted successfully!');
                removeMeasure(measureId);
                onClose();
            }
        } catch (error) {
            console.error('Error deleting measure:', error.response ? error.response.data : error.message);
            toast.error('An error occurred while deleting the measure.');
            
        } finally {
            setIsSubmitting(false);
        }
    };
    
    
    if (!isOpen) return null;

    return (
        <div className="overlay">
            <div className="popup-user" ref={popupRef}>
                <div className="popup-header">
                    <Flag01Icon size={24} color="#FF3B30" />
                    <h4 className="title">Delete</h4>
                </div>
                <p className="paragraph-red">Do you really want to delete this Measure? (With ID {measureId})</p>
                <div className="buttons">
                    <SecondaryButton isLoading={false} onClick={onClose} buttonText={'Cancel'} />
                    <PrimaryButton 
                        isLoading={isSubmitting} 
                        processingText={'Deleting'} 
                        buttonText={'Delete'} 
                        onClick={handleSubmit} 
                    />
                </div>
            </div>
        </div>
    );
};

export default DeleteMeasureData;
