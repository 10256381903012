import React from 'react'
import LoginForm from '../../components/forms/LoginForm'
import '../../assets/css/pages/authPages/authPages.css'
import { Helmet } from 'react-helmet'
import { siteTitle } from '../../api'
const SessionExpired = () => {
    return (
        <>
            <Helmet>
                <title>Session Expired | {siteTitle}</title>
            </Helmet>
            <div className='auth-pages'>
                <div className="page-content">
                    <img className='logo' src="/logo192.png" alt="logo" />
                    <h2>Session expired</h2>
                    <p>You need to login again. Please enter your email and password</p>
                    <LoginForm />
                </div>
            </div>
        </>
    )
}

export default SessionExpired
