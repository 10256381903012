import React, { useState } from 'react';
import "../../assets/css/hospitals/hospitals.css";
import { FileValidationIcon } from 'hugeicons-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../api';
import toast from 'react-hot-toast';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import { TextInput } from '../forms/Input';

const AddHospitalListPopup  = ({ onClose, onAddHospital }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [hospitalName, setHospitalName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
        const newHospital = {
            name: hospitalName
        };
        console.log(newHospital)
        const token = Cookies.get('accessToken');
        setIsLoading(true);
        const response = await axios.post(`${API_URL}/hospitals/new_hospital/`, newHospital, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200 || response.status === 201) {
          onAddHospital(response.data);
          toast.success("Hospital successfully added");
          onClose();
window.location.href = '/all_hospitals/';
}else {
            toast.error("Failed to add Hospital");
        }
    } catch (error) {
        console.error(error);
        if (error.response) {
            toast.error('Error while adding target: ' + (error.response.data.detail || 'Unknown error'));
        } else {
            toast.error('Something went wrong. Please try again');
        }
        setIsLoading(false);
    }
    setIsSubmitting(false);
};

  const handleClose = () => {
    if (showSuccess) {
      setShowSuccess(false);
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <div className="popup-overlayer">
      <div className="popup-content">
        {showSuccess ? (
          <div className="success-message">
            <div className='success-icon'>
              <FileValidationIcon 
                size={70} 
                color={"#40C057"}
                variant={"stroke"}
              />
            </div>
            <h2>Successful</h2>
            <p>Your hospital has been added successfully.</p>
<button onClick={handleSubmit}>Continue</button>
          </div>
        ) : (
          <div className="form-container">
            <h2>Add New Hospital</h2>
<form onSubmit={handleSubmit}>
<TextInput 
  type="text" 
  name="hospitalName" 
  placeholder="Hospital Name" 
  value={hospitalName}
  setValue={setHospitalName} 
/>

             
              <div className='buttons'>
                    <SecondaryButton
                        onClick={onClose}
                        buttonText='Cancel'
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                        type='submit'
                        processingText='Submitting'
                        buttonText='Save'
                    />
                </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddHospitalListPopup ;
