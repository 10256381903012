import React, { useEffect, useState } from 'react';
import api, { API_URL } from '../../api';
import { CleanIcon, Edit02Icon, Delete01Icon, PlusSignIcon } from 'hugeicons-react';
import "../../assets/css/position/position.css";
import { PrimaryButton } from '../../components/forms/buttons';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import FormattedDate from '../../services/formatDate';
import AddPositionPopup from '../../components/positionPopup/AddPositionPopup';
import EditPositionPopup from '../../components/positionPopup/EditPositionPopup';
// import DeletePositionPopup from '../../components/positionPopup/DeletePositionPopup'; 
import toast from 'react-hot-toast';
import DeletePositionPopup from '../../components/positionPopup/DeletePositonPopup';

const PositionPageContent = (onClose) => {
    const [positions, setPositions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [editPosition, setEditPosition] = useState(null);
    const [deletePositionId, setDeletePositionId] = useState(null);

    const fetchPositions = async () => {
        try {
            const response = await api.get(`${API_URL}/position/`);
            if (response.status === 200) {
                setPositions(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Failed to fetch positions:', error);
            setIsLoading(false);
        }
    };

    const handleAddPositionSubmit = () => {
        fetchPositions(); 
        setIsPopupVisible(false);
    };

    const handleEditPositionSubmit = (updatedPosition) => {
        setPositions((prevPositions) =>
            prevPositions.map((pos) =>
                pos.id === updatedPosition.id ? { ...pos, ...updatedPosition } : pos
            )
        );
        setIsPopupVisible(false);
    };

    const handleDeletePosition = async (id) => {
        try {
            await api.patch(`${API_URL}/position/${id}/`); 
            toast.success('Position deleted successfully');
            // setPositions((prevPositions) => prevPositions.filter((pos) => pos.id !== id));
            onClose();
        } catch (error) {
            console.error('Failed to delete position:', error);
        }
    };

    const handleOpenDeletePopup = (id) => {
        setDeletePositionId(id);  
    };

    const handleCloseDeletePopup = () => {
        setDeletePositionId(null);  
    };

    const handleConfirmDelete = () => {
        if (deletePositionId) {
            handleDeletePosition(deletePositionId);
            handleCloseDeletePopup();  
        }
    };

    const handleAddPosition = () => {
        setEditPosition(null);
        setIsPopupVisible(true);
    };

    const handleEditPosition = (position) => {
        setEditPosition(position);
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
        setEditPosition(null);
    };

    useEffect(() => {
        fetchPositions();
    }, []);



    return isLoading ? 'Getting data..' : (
        <div className='position-page'>
            {isPopupVisible && (
                editPosition ? (
                    <EditPositionPopup
                        position={editPosition}
                        onClose={handleClosePopup}
                        onSubmit={handleEditPositionSubmit}
                    />
                ) : (
                    <AddPositionPopup
                        onClose={handleClosePopup}
                        onSubmit={handleAddPositionSubmit}
                    />
                )
            )}

            {deletePositionId && (
    <DeletePositionPopup
        positionId={deletePositionId}
        onClose={handleCloseDeletePopup}
        onSubmit={handleDeletePosition} 
    />
)}
            
            {localStorage.getItem("position") === "Hospital Admin" ? '' : (
                <PrimaryButton
                    isLoading={false}
                    onClick={handleAddPosition}
                    processingText={'Submitting'}
                    iconClass={<PlusSignIcon />}
                    buttonText={'Add a role'}
                />
            )}

            {positions.length > 0 ? (
                <table className="position-table">
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Description</th>
                            <th>Date Created</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {positions.map((pos) => (
                            <tr key={pos.id}>
                                <td>{pos.name}</td>
                                <td>{pos.description}</td>
                                <td><FormattedDate dateString={pos.date_created} /></td>
                                {/* <td>
                                    <Delete01Icon onClick={() => handleOpenDeletePopup(pos.id)}/>
                                    <Edit02Icon onClick={() => handleEditPosition(pos)}/>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                'No data available'
            )}
        </div>
    );
};

export default PositionPageContent;
