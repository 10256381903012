import React from 'react'
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm'

const ForgetPassword = () => {
    return (
        <div className='auth-pages'>
            <div className="page-content">
                <img className='logo' src="/logo192.png" alt="logo" />
                <h2>Forgot password?</h2>
                <p>No problem. Just verify your email and we'll help you reset it!</p>
                <ForgotPasswordForm />
            </div>
        </div>
    )
}

export default ForgetPassword
