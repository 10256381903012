export const msalConfig = {
    auth: {
        clientId: '7c88fb74-5f1a-463f-9cd9-1914236090f8',
        authority: 'https://login.microsoftonline.com/28ee6dbe-d6ae-40b3-88ed-f1c50e995ea0/',
        redirectUri: 'http://localhost:3000/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};


export const loginRequest = {
    scopes: ['User.Read'], 
};
