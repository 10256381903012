import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { CodeCircleIcon, CodeIcon, EyeIcon, Loading03Icon, LockPasswordIcon, MailSend01Icon } from 'hugeicons-react'
import toast from 'react-hot-toast'
import axios from 'axios'
import { API_URL } from '../../api'
import { TextInput } from './Input'
import { PrimaryButton } from './buttons'
const PasswordResetFrom = () => {
    const resetPasswordEmails = Cookies.get('resetPasswordEmails')
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordResetCode, setPasswordResetCode] = useState("")

    const handlePasswordReset = async () => {

        if (!resetPasswordEmails || !passwordResetCode || !password) {
            toast.error('Please enter a valid email and code ')
            return
        }

        console.log("credentials are: ", resetPasswordEmails, password, passwordResetCode)
        try {
            setIsLoading(true)
            const credentials = {
                "email": resetPasswordEmails,
                "code": passwordResetCode,
                "new_password": password
            }
            const response = await axios.post(`${API_URL}/accounts/password-reset/`, credentials)

            if (response.status === 200) {
                Cookies.set('resetPasswordToken', response.data.token)
                toast.success('Password is rest successful. You can now login')
                setIsLoading(true)
                setTimeout(() => {
                    window.location.href = '/login/'
                }, 3000);
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Something went wrong. Please try again')
            }
            setIsLoading(false)
        }
    }
    return (
        <div className='form'>
            <form>
                <TextInput iconClass={<MailSend01Icon />} type={'text'} id={'passwordResetCode'} name={passwordResetCode} value={passwordResetCode} setValue={setPasswordResetCode} placeholder={'Enter password reset code'} />
                <TextInput iconClass={<LockPasswordIcon />} placeholder={'Enter new password'} type={'password'} name={'password'} value={password} setValue={setPassword} />
            </form>
            <PrimaryButton buttonText={'Reset password'} processingText={'Resetting password'} onClick={handlePasswordReset} isLoading={isLoading} />

        </div>
    )
}

export default PasswordResetFrom
