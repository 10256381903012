import React, {useState} from 'react';
import { DataListInput, DateInput } from '../forms/Input';
import { Flag01Icon } from 'hugeicons-react';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';



function EditHospitalPopup() {
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [country, setCountry] = useState('');
    const [showEditPopup, setShowEditPopup] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = () => {
        setIsSubmitting(true)
        setTimeout(() => {
            setIsSubmitting(false)
        }, 5000);
    }

    const handleClosePopup = () => {
        setShowEditPopup(false);
      };
  return (
    <div className="edit-hospital-popup">
        <div className="title">
            <h2>Edit Hospital Details</h2>
            
        </div>
        <div className="banner">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est cum ducimus, 
            perspiciatis possimus odio animi deserunt veniam accusamus!
        </div>
        <div className="select-hospital">
        <DataListInput
                iconClass={<Flag01Icon />}
                id={"hospitals"}
                name={"hospitals"}
                placeholder={"Select a hospital"}
                value={country}
                setValue={setCountry}
                options={["Pawhuska", "Mangum", "Carnegie", "Seiling", "Prague"]}
            />
        </div>
        <div className="date">
        <DateInput date={date} setDate={setDate} choices={['day', 'month', 'year']} />
        </div>

        <div className="buttons">
            <div className="cancel" ><SecondaryButton onClick={handleClosePopup} buttonText={'Cancel'}/></div>
            <div className="save"><PrimaryButton isLoading={isSubmitting} onClick={handleSubmit} processingText={'Saving'} buttonText={'Save'} /></div>
        </div>
    </div>
  )
}

export default EditHospitalPopup;