import React from 'react';

const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
    
};

const FormattedDate = ({ dateString }) => {
    const formattedDate = formatDate(dateString);

    return (
        <div>
            <p>{formattedDate}</p>
        </div>
    );
};

export default FormattedDate;
