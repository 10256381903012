import React, { useEffect, useState } from 'react'
import '../../assets/css/components/dashboardContainer/dashboardContainer.css';
import { Link, useLocation } from 'react-router-dom';
import api, { API_URL, siteTitle } from '../../api';
import { Analytics02Icon, ArrowDown01Icon, ArrowUp01Icon, BeltIcon, Briefcase01Icon, Cancel01Icon, ChartIcon, CircleIcon, DashboardSquare01Icon, Hospital01Icon, Note01Icon, Note05Icon, Notification02Icon, Target01Icon, UserIcon, UserMultiple02Icon, UserSettings01Icon } from 'hugeicons-react';
import axios from 'axios';
import Cookies from 'js-cookie'; 

const Dropdown = ({ options, isOpen, toggleDropdown }) => {
    return (
        <div className="drop-down">
          
            <div className="drop-down-title" onClick={toggleDropdown}>
                <div className="title">
                    <Hospital01Icon size={24} />
                    <span>Hospitals</span>
                </div>
              
                {isOpen ? (
                    <ArrowUp01Icon className="drop-down-icon" />
                    
                ) : (
                    <ArrowDown01Icon className="drop-down-icon" />
                )}
            </div>

            {isOpen && (
                <div className="drop-down-content">
                    {options.map((option, index) => (
                        <Link key={index} to={option.path} className="menu-item">
                            {option.icon ? (
                                <div className="initials">{option.icon}</div>
                            ) : (
                                <CircleIcon size={12} />
                            )}
                            <span>{option.name}</span>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

const SideBar = ({ mobileMenuOpen, setMobileMenuOpen }) => {
    const location = useLocation()
    const currentPath = location.pathname
    const [position, setPosition] = useState('');
    const [hospital, setHospital] = useState('');
    const [hospitalId, setHospitalId] = useState('');
    const [path, setPath] = useState('');
    const [isSingleHospital, setIsSingleHospital] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const [hospitalsList,setHospitalsList] = useState([

    ])

    const fetchHospitalList = async ()=>{
        const response = await api.get(
            `${API_URL}/hospitals/`
          );

          setIsSingleHospital(false)
         
        if (response.status=== 200 ){
            const data = response.data
            setHospitalsList([        {
                'icon': 'A', 'name': 'All Hospitals', 'path': '/hospitals/'
            }])
            for (let hospital in data){
               
                const icon = data[hospital]["name"].charAt(0)
                const name = data[hospital]['name'].split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                const hospitalId = data[hospital]["id"]
                const path = `/hospitals/${name}/${hospitalId}/`
                
                const item = {
                    'icon': icon, 'name': name, 'path': path, "hospitalId": hospitalId
                }

                setHospitalsList(prevHospital =>[...prevHospital,item])
              }
        }   
    }

   

    useEffect(()=>{

        const fetchUserPermission = async () =>{
            
            setPosition(localStorage.getItem("position"));
            setHospital(localStorage.getItem("hospital"));
            setHospitalId(localStorage.getItem("hospital_id"));

            if (localStorage.getItem("position") ==="Hospital Admin"){

                setPath(`/hospitals/${localStorage.getItem("hospital")}/${localStorage.getItem("hospital_id")}/`)
                setIsSingleHospital(true)
                
            }else{
                setPath(`/`)
                fetchHospitalList()
            }
    }

        fetchUserPermission()
    
    },[])

    // const hospitalsList = [
    //     {
    //         'icon': 'A', 'name': 'All Hospitals', 'path': '/hospitals/'
    //     },
    //     {
    //         'icon': 'P', 'name': 'Pawhuska', 'path': `/hospitals/Pawhuska/`
    //     },
    //     {
    //         'icon': 'C', 'name': 'Carnegiehhhhh', 'path': `/hospitals/Carnegie/`
    //     },
    //     {
    //         'icon': 'M', 'name': 'Mangumhhhhhhhhh', 'path': `/hospitals/Mangum/`
    //     },
    //     {
    //         'icon': 'S', 'name': 'Seiling','path': `/hospitals/Seiling/`
    //     },
    //     {
    //         'icon': 'P', 'name': 'Prague', 'path': `/hospitals/Prague/`
    //     },
    // ]

    // Toggle dropdown open/close state on icon click
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen); // <-- This toggles the dropdown
    }

    return (
        <div className={`side-bar ${mobileMenuOpen ? 'show' : 'hide'}`}>
            <Link to="/" className='branding'>
                <img src="/ch-white-logo.svg" alt="logo" />
                <h2 className='site-title'>{siteTitle}</h2>
            
            </Link>

            <Cancel01Icon size={32} className='close-menu' onClick={() => setMobileMenuOpen(false)} />
            <div className="menu-items">
                <Link to={path} className={`menu-item ${currentPath === path ? 'active' : ''}`}>
                    <DashboardSquare01Icon size={24} />
                    <span> Dashboard</span>
                </Link>
                <Link to="/users/" className={`menu-item ${currentPath === '/users/' ? 'active' : ''}`}>
                    <UserMultiple02Icon size={24} />
                    <span>Users</span>
                </Link>
               

                {
                    !isSingleHospital && (
                        <Dropdown 
                            options={hospitalsList}
                            isOpen={isDropdownOpen}  
                            toggleDropdown={toggleDropdown}  
                        />
                    )
                }
                
                <Link to="/measures/" className={`menu-item ${currentPath === '/measures/' ? 'active' : ''}`}>
                    <Analytics02Icon size={24} />
                    <span>Measures</span>
                </Link>
<Link to="/measure_data/" className={`menu-item ${currentPath === '/measure_data/' ? 'active' : ''}`}>
                    <Analytics02Icon size={24} />
<span>Measure Data</span>
                </Link>

                {/* <Link to="/notifications/" className={`menu-item ${currentPath === '/notifications/' ? 'active' : ''}`}>
                    <Notification02Icon size={24} />
                    <span>Notifications</span>
                </Link> */}

                <Link to="/positions/" className='menu-item'>
                    <Briefcase01Icon size={24} />
                    <span>Positions</span>
                </Link>

                <Link to="/targets/" className={`menu-item ${currentPath === '/targets/' ? 'active' : ''}`}>
                    <Target01Icon size={24} />
                    <span>Targets</span>
                </Link>

                {/* <Link to="/account/" className={`menu-item ${currentPath === '/account/' ? 'active' : ''}`}>
                    <UserSettings01Icon size={24} />
                    <span>Account</span>
                </Link> */}

                {/* <Link to="/reports/" className={`menu-item ${currentPath === '/reports/' ? 'active' : ''}`}>
                    <Note05Icon size={24} />
                    <span>Reports</span>
                </Link> */}

            </div>
        </div>
    )
}

export default SideBar
