import React from 'react'
import DashboardContainer from '../../components/dashboard/DashboardContainer'

const SettingsPage = () => {
    return (
        <DashboardContainer pageTitle={'Settings'} />
    )
}

export default SettingsPage
