import React, { useState, useEffect, useRef } from 'react';
import api, { API_URL } from '../../api';


const ViewPositionDetailsPopup = ({ positionId, onClose, permissions}) => {
    const [positionDetails, setPositionDetails] = useState(null);
    console.log("data pos");

    console.log(permissions);
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                onClose(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);
    
    useEffect(() => {
        const fetchPositionDetails = async () => {
            try {
                const response = await api.get(`${API_URL}/position/`);
                if (response.status === 200) {
                    setPositionDetails(response.data);
                }
            } catch (error) {
                console.error("Failed to fetch position details:", error);
            }
        };

        fetchPositionDetails();
    }, [positionId]);

    if (!positionDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="view-position-popup-overlay">
            <div className="view-position-popup " ref={popupRef}>
                {/* <h3>Position Details</h3> */}
                <div className='details'>
                <p className='positionId'>{permissions.position_id}</p>
                <h3> {permissions.name}</h3>
                <p className='description'>{permissions.description}</p>
                {/* <p>Date Created:{permissions.date_created}</p> */}
                </div>
                
                <div className='permissions'>
                    <h3>Permissions</h3>
                    <ul>
                    {permissions.details.map((permission, index) => (
    <li key={index}>{permission}</li>
))}
                    
                    </ul>

                </div>

                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ViewPositionDetailsPopup;
