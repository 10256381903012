import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../api';
import { ToastContainer, toast } from 'react-toastify';
import { TextInput } from '../../forms/Input';
import { PrimaryButton, SecondaryButton } from '../../forms/buttons';

const EditProfilePopup = ({ userId, isOpen, closePopup, profile, onSave }) => {
  const [updatedProfile, setUpdatedProfile] = useState({
    username: profile?.username || '',
    email: profile?.email || '',
    first_name: profile?.first_name || '',
    last_name: profile?.last_name || '',
    hospital: profile?.hospital || '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await axios.put(`${API_URL}/user/update_user/${userId}/`, updatedProfile);
      toast.success('Profile updated successfully');
      onSave(response.data);
      closePopup();
      window.location.reload(); 

    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    isOpen && (
      <div className="overlay">
        <div className="popup-user">
          <form onSubmit={handleSubmit}>
            <h2>Edit Profile</h2>
            <TextInput
              id="username"
              name="username"
              placeholder="Username"
              value={updatedProfile.username}
              setValue={(value) => setUpdatedProfile((prev) => ({ ...prev, username: value }))}
            />
            <TextInput
              id="email"
              name="email"
              placeholder="Email"
              value={updatedProfile.email}
              setValue={(value) => setUpdatedProfile((prev) => ({ ...prev, email: value }))}
            />
            <TextInput
              id="first_name"
              name="first_name"
              placeholder="First Name"
              value={updatedProfile.first_name}
              setValue={(value) => setUpdatedProfile((prev) => ({ ...prev, first_name: value }))}
            />
            <TextInput
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              value={updatedProfile.last_name}
              setValue={(value) => setUpdatedProfile((prev) => ({ ...prev, last_name: value }))}
            />
            <TextInput
              id="hospital"
              name="hospital"
              placeholder="Hospital"
              value={updatedProfile.hospital}
              setValue={(value) => setUpdatedProfile((prev) => ({ ...prev, hospital: value }))}
              disabled={true}
            />
<div className="popup-buttons">
<PrimaryButton isSubmitting={isSubmitting} buttonText="Save" type="submit" processingText={'Editing User Details'} isLoading={isSubmitting} />     
<SecondaryButton onClick={closePopup} type="button" isLoading={false} buttonText="Cancel">Cancel</SecondaryButton>

</div>
          </form>
        </div>
      </div>
    )
  );
};

export default EditProfilePopup;
