import { useState } from 'react';
import '../../assets/css/components/forms/forms.css';
import { Flag01Icon } from 'hugeicons-react';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";


export const TextInput = ({ iconClass, label, name, type, id, placeholder, value, setValue, disabled }) => {
    const [isActive, setIsActive] = useState(false)
    const handleIsActive = () => {
        setIsActive(!isActive)
        document.querySelectorAll('.input').forEach(input => input.classList.remove('active'));
        document.getElementById(`input${id}`).classList.add('active');
    }
    return (
        <div className="field">
            <label htmlFor={id}>{label}</label>
            <div onClick={handleIsActive} className="input" id={`input${id}`}>
                {iconClass}
<input value={value} onChange={(e) => setValue(e.target.value)} type={type || 'text'} name={name} id={id} placeholder={placeholder} disabled={disabled}/>
            </div>
        </div>
    )
}
// export const TextAreaInput = ({ iconClass, label, name, id, placeholder, value, setValue }) => {
//     const [isActive, setIsActive] = useState(false);
    
//     const handleIsActive = () => {
//         setIsActive(!isActive);
//         document.querySelectorAll('.textarea').forEach(textarea => textarea.classList.remove('active'));
//         document.getElementById(`textarea${id}`).classList.add('active');
//     };}

export const TextAreaInput = ({ iconClass, label, name, id, placeholder, value, setValue }) => {
    const [isActive, setIsActive] = useState(false);
    
    const handleIsActive = () => {
        setIsActive(!isActive);
        document.querySelectorAll('.textarea').forEach(textarea => textarea.classList.remove('active'));
        document.getElementById(`textarea${id}`).classList.add('active');
    };}

export const DataListInput = ({ id, name, label, placeholder, iconClass, options, value, setValue }) => {
    const [optionsOpen, setOptionsOpen] = useState(false)
    const handleToggleOptions = () => {
        setOptionsOpen(!optionsOpen)
    }

    const handleSelectOption = (option) => {
        setValue(option)
        setOptionsOpen(false)
    }
    return (
        <div className="field">
            <label htmlFor={name}>{label}</label>
            <div onClick={handleToggleOptions} className="input">
                {iconClass}
                <input type="text" placeholder={placeholder} value={value} />
            </div>

            {
                optionsOpen &&
                <div className="options">
                    {options.map((option, index) => (
                        <p className='option' key={index} onClick={() => handleSelectOption(option)}>{option}</p>
                    ))}
                </div>
            }
        </div>
    );
};



export const DateInput = ({ date, setDate, choices }) => {
    return (
        <div className="field">
            <div className="input date-input">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        // views={choices}
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}
