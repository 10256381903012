import React, { useState } from 'react'
import SideBar from './SideBar'
import DashboardHeader from './DashboardHeader'

const DashboardContainer = ({ content, pageTitle }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(true)
    return (
        <div className='dashboard'>
            <SideBar mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
            <div className="dashboard-content">
                <DashboardHeader mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} pageTitle={pageTitle} />
                <div className="content">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default DashboardContainer
