import React from "react";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "./authContext";
import SessionExpired from "../pages/auth/SessionExpired";

function ProtectedRoute({ children }) {
    const { loading, isAuth, } = useAuthentication()
    if (isAuth == null) {
        return null
    }
    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuth ? children : <SessionExpired />;
}

export default ProtectedRoute
