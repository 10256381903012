import React, { useState, useEffect, useRef } from "react";
import { DataListInput, DateInput, TextInput } from "../forms/Input";
import { Flag01Icon } from "hugeicons-react";
import { PrimaryButton, SecondaryButton } from "../forms/buttons";
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import dayjs from 'dayjs'; // Import dayjs for date handling
import { API_URL } from '../../api';
import MessageDescription from "../PopoupMessage/messageDescription";

const EditTarget = ({ targetData, onClose, onSubmit }) => {
  const [formData, setFormData] = useState(targetData);
  const [country, setCountry] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [measures, setMeasures] = useState([]);
  const [typeOptions] = useState(['numerical', 'percentage']);
  const [type, setType] = useState(targetData.type || '');
  const [valuesOptions] = useState(['Lower is better', 'Greater is better', 'N/A']);
  const [values,setValues] = useState(targetData.value_condition || '');

  const popupRef = useRef(null);

  useEffect(() => {
      const handleClickOutside = (e) => {
          if (popupRef.current && !popupRef.current.contains(e.target)) {
              onClose(); 
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [onClose]);

  useEffect(() => {
    const fetchMeasures = async () => {
        const token = Cookies.get('accessToken');
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/measures/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = response.data;
            const measuresArray = data.map(item => item['name']); 
            setMeasures(measuresArray);
        } catch (error) {
            console.error('Error fetching measures:', error);
            toast.error('Failed to load measures');
        } finally {
            setIsLoading(false);
        }
    };

    fetchMeasures();
  }, []);

  useEffect(() => {
    setFormData(targetData);
  }, [targetData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTypeChange = (value) => {
    setType(value);
    setFormData(prevFormData => ({
      ...prevFormData,
      targetType: value,
    }));
  };
  
  const handleValuesChange = (value) => {
    setValues(value); 
    setFormData(prevFormData => ({
      ...prevFormData,
      value_condition: value, 
    }));
  };

  const handleDateChange = (date, name) => {
    const formattedDate = dayjs(date).isValid() ? dayjs(date) : null;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: formattedDate,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();  
    setIsSubmitting(true);
    const token = Cookies.get('accessToken');
    const { target_id, ...dataToSend } = formData;  
  
    try {
      const response = await axios.put(`${API_URL}/targets/update_target/${target_id}/`, dataToSend, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      toast.success('Target updated successfully!');
      onSubmit(response.data); 
      window.location.href = '/targets/';
    } catch (error) {
      console.error('Error updating target:', error);
      toast.error('Failed to update target');
    } finally {
      setIsSubmitting(false);
      onClose(); 
    }
  };
  
  return (
    <div className="popup-overlay">
      <div className="popup-content add-target-popup">
        <h2>Edit Target</h2>
        <form onSubmit={handleSubmit} ref={popupRef}>
          <MessageDescription />
          <DataListInput
            iconClass={<Flag01Icon />}
            id={"measures"}
            name={"measureName"}
            placeholder={"Measure Name"}
            value={formData.measure}
            onChange={handleChange}
            setValue={setCountry}
            options={measures}
          />
          <TextInput
            type={"text"}
            id={"targetValue"}
            name={"targetValue"}
            placeholder={"Target value"}
            value={formData.value}
            setValue={(value) => handleChange({ target: { name: 'value', value } })}
          />
          <DataListInput
            id="type"
            name="targetType"
            placeholder="Select Type"
            value={type} 
            options={typeOptions} 
            setValue={handleTypeChange}
          />
          <DataListInput
  id="values"
  name="targetValues"
  placeholder="Select Values"
  value={values} 
  options={valuesOptions} 
  setValue={handleValuesChange}
/>


          <div className="dateInput">
            <div className='label-dates'>
              <label>Start Date</label>
              <div
            onMouseDown={(e) => e.stopPropagation()} // Prevent popup close
            onClick={(e) => e.stopPropagation()} // Prevent popup close
        >
              <DateInput
                date={formData.starting_date ? dayjs(formData.starting_date) : null}
                setDate={(date) => handleDateChange(date, 'starting_date')}
                placeholder={"Start Date"}
                label={"Start Date"}
                choices={["day", "month", "year"]}
              />
              </div>
            </div>
            <div className='label-dates'>
              <label>End Date</label>
              <div
            onMouseDown={(e) => e.stopPropagation()} // Prevent popup close
            onClick={(e) => e.stopPropagation()} // Prevent popup close
        >
              <DateInput
                date={formData.ending_date ? dayjs(formData.ending_date) : null}
                setDate={(date) => handleDateChange(date, 'ending_date')}
                placeholder={"End Date"}
                label={"End Date"}
                choices={["day", "month", "year"]}
              />
              </div>
            </div>
          </div>
          <div className="buttons">
            <SecondaryButton
              onClick={onClose}
              buttonText={"Cancel"}
            />
            <PrimaryButton
              isLoading={isSubmitting}
              processingText={"Submitting"}
              buttonText={"Save"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTarget;
